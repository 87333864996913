import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import { FETCH_ARTWORK_REQUEST, FETCH_ARTWORK_SUCCESS, FETCH_ARTWORK_FAILURE } from './constants';

const fetchArtworkRequest = () => ({ type: FETCH_ARTWORK_REQUEST });
const fetchArtworkSuccess = (payload) => ({ type: FETCH_ARTWORK_SUCCESS, payload });
const fetchArtworkFailure = (errors) => ({ type: FETCH_ARTWORK_FAILURE, errors });

export const fetchArtwork = (artworkId) => async (dispatch) => {
  dispatch(fetchArtworkRequest());
  try {
    const response = await bdReq.get(`/artwork/${artworkId}`);
    if (response.success) {
      return dispatch(fetchArtworkSuccess(response.body));
    }
    return dispatch(fetchArtworkFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchArtworkFailure(e));
  }
};
