import React from 'react';
import styled from '@emotion/styled';

import Button from '../../components/Button';

const Wrapper = styled.div`
  text-align: center;
`;

const Headline = styled.h1`
  font-weight: bold;
`;

const Subhead = styled.h2``;

export default function PageNotFound() {
  return (
    <Wrapper>
      <Headline>SORRY, THAT PAGE DOESN’T EXIST!</Headline>
      <Subhead>WE COULDN'T FIND THE PAGE YOU WERE LOOKING FOR.</Subhead>
      <Button small to="/">
        To Discover
      </Button>
      <Button ghost small href="http://blackdove.zendesk.com">
        Get Help
      </Button>
    </Wrapper>
  );
}
