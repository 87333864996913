import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { space } from 'styled-system';
import { Flex, Box } from 'reflexbox';
import { Search, Exit } from 'emotion-icons/ion-ios';
import { useHistory } from 'react-router-dom';
import Dropdown from '../../../../Dropdown';
import { Form, TextInput } from '../../../../Form';
import bdAuth from '@bd-uikit-web/js-helpers/bdAuth';

const Wrapper = styled.div`
  ${space}
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseLighter};
`;

const StyledInput = styled(TextInput)`
  background: none;
  border: none;
  margin-bottom: 0;

  &:focus {
    border: none;
  }
`;

const StyledSearch = styled(Search)`
  margin-top: 0.5em;
  fill: ${({ theme }) => theme.colors.whiteFaded};
`;

const Header = () => {
  const user = useSelector((state) => state.user);
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isNotHeader = ref.current && !ref.current.contains(event.target);
      const isNotDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target);

      if (isNotHeader && isNotDropdown) {
        setVisible(false);
      }
    }; 

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, dropdownRef, visible]);

  const onLogoutClick = () => {
    console.log('heree')
    bdAuth.clear();
    history.push('/login');
  }

  const items = [{
    id: 1,
    title: 'Logout',
    icon: <Exit />,
    action: () => onLogoutClick()
  }];

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    history.push(`/search/${searchValue}`);
  };

  const handleButtonClick = useCallback(() => setVisible(!visible), [visible]);

  return (
    <Wrapper ml={100} p={20}>
      <Flex style={{ position: "relative" }}>
        <Box>
          <StyledSearch height={30} />
        </Box>
        <Box width={1}>
          <Form onSubmit={handleSearchSubmit}>
            <StyledInput
              type="text"
              name="search"
              placeholder="Search... e.g. tags, names"
              onChange={handleInputChange}
            />
          </Form>
        </Box>
        <Box width={100} mt={10}>
          <span ref={ref} onClick={handleButtonClick}>Hi, {user?.data?.user?.givenName || 'Friend'}</span>
          <Dropdown 
            dropdownRef={dropdownRef}
            visible={visible}
            listItems={items}
          />
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default Header;
