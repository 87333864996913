import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { closeModal } from '../../../../../store/app/actions';

import SubscribeModal from '../../../../Modals/Subscribe';
import AddCardModal from '../../../../Modals/AddCard';
import AddArtworkModal from '../../../../Modals/AddArtwork';
import PlayArtworkModal from '../../../../Modals/PlayArtwork';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.base};
  padding: 2em;
  width: 50%;
  min-height: 25%;
`;

const useOutsideAlerter = (ref) => {
  const dispatch = useDispatch();
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      dispatch(closeModal());
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const Modal = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const app = useSelector((state) => state.app);
  useOutsideAlerter(containerRef);
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  });

  const renderModal = () => {
    switch (app.currentModal) {
      case 'SUBSCRIBE':
        return <SubscribeModal closeModal={handleCloseModal} />;
      case 'ADD_CARD':
        return <AddCardModal closeModal={handleCloseModal} />;
      case 'ADD_ARTWORK':
        return <AddArtworkModal closeModal={handleCloseModal} />;
      case 'PLAY_ARTWORK':
        return <PlayArtworkModal closeModal={handleCloseModal} />;
      default:
        return null;
    }
  };

  if (!app.modalOpen) {
    return null;
  }

  return (
    <Wrapper>
      <ModalContainer ref={containerRef}>{renderModal()}</ModalContainer>
    </Wrapper>
  );
};

export default Modal;
