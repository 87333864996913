import { combineReducers } from 'redux';

import app from './store/app/reducer';
import notifications from './store/notifications/reducer';
import user from './store/user/reducer';
import subscription from './store/subscription/reducer';
import priceTiers from './store/priceTiers/reducer';
import cards from './store/cards/reducer';
import discover from './store/discover/reducer';
import staff from './store/staff/reducer';
import playlists from './store/playlists/reducer';
import selectedPlaylist from './store/selectedPlaylist/reducer';
import selectedArtwork from './store/selectedArtwork/reducer';
import selectedArtist from './store/selectedArtist/reducer';
import followers from './store/followers/reducer';
import devices from './store/devices/reducer';
import newArtwork from './store/newArtwork/reducer';
import thumbnail from './store/thumbnail/reducer';

const rootReducer = combineReducers({
  app,
  notifications,
  user,
  subscription,
  priceTiers,
  cards,
  discover,
  staff,
  playlists,
  selectedPlaylist,
  selectedArtwork,
  selectedArtist,
  followers,
  devices,
  newArtwork,
  thumbnail
});

export default rootReducer;
