import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import { space } from 'styled-system';
import { Link } from 'react-router-dom';

const baseStyles = ({ bgColor, bgHoverColor, theme }) =>
  css`
    background: ${bgColor || theme.colors.primary};
    border: 1px solid ${bgColor || theme.colors.primary};
    border-radius: 2px;
    cursor: pointer;
    font-weight: 300;
    font-size: 1.1em;
    color: ${theme.colors.white};
    text-decoration: none;
    text-align: center;
    margin-bottom: 1.2em;
    margin-right: 1.5em;
    padding: 1em 1.8em;
    transition: background 0.2s ease, border-color 0.2s ease, color 0.2s ease;
    display: inline-block;

    &:hover {
      background: ${bgHoverColor || theme.colors.primaryLighter};
    }
  `;

const blockStyles = css`
  display: block;
  width: 100%;
`;

const roundStyles = css`
  border-radius: 100px;
`;

const ghostStyles = ({ bgColor, theme }) =>
  css`
    background: none;
    border: 1px solid ${bgColor || theme.colors.whiteFaded};

    &:hover {
      background: ${bgColor || theme.colors.white};
      border: 1px solid ${bgColor || theme.colors.white};
      color: ${theme.colors.black};
    }
  `;

const outlineStyles = ({ theme }) =>
  css`
    border: 2px solid ${theme.colors.white};
  `;

const rightStyles = () =>
  css`
    float: right;
  `;

const smallStyles = () =>
  css`
    padding: 0.5em 1em;
  `;

const disabledStyles = ({ theme }) => {
  return css`
    background: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondaryLighter};
    color: ${theme.colors.secondaryLighter};

    &:hover {
      background: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.secondaryLighter};
      color: ${theme.colors.secondaryLighter};
      cursor: not-allowed;
    }
  `;
};

const StyledButton = styled('button', {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !['block', 'round', 'outline', 'ghost', 'right', 'small'].includes(prop)
})`
  ${baseStyles}
  ${({ block }) => block && blockStyles}
  ${({ round }) => round && roundStyles}
  ${({ outline }) => outline && outlineStyles}
  ${({ ghost }) => ghost && ghostStyles}
  ${({ right }) => right && rightStyles}
  ${({ small }) => small && smallStyles}
  ${({ disabled }) => disabled && disabledStyles}
  ${space}
`;

const Button = ({ to, href, children, ...props }) => {
  if (to) {
    return (
      <StyledButton to={to} as={Link} {...props}>
        {children}
      </StyledButton>
    );
  }

  if (href) {
    return (
      <StyledButton href={href} as="a" {...props}>
        {children}
      </StyledButton>
    );
  }

  return (
    <StyledButton type="button" as="button" {...props}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

Button.defaultProps = {
  to: null,
  href: null,
  children: null
};

export default Button;
