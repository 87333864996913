import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Filing, Star } from 'emotion-icons/ion-ios';

// TODO: bdReq and bdAuth should be extracted into a proper package
import bdReq from '@bd-uikit-web/js-helpers/bdReq';

import Loader from '../../Loader';

import { fetchPlaylists } from '../../../store/playlists/actions';
import { queueNotification } from '../../../store/notifications/actions';

const Wrapper = styled.div``;

const PlaylistList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.baseLighter};
  margin-top: 2em;
`;

const PlaylistItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseLighter};
`;

const PlaylistLink = styled.a`
  color: white;
  padding: 5px 10px;
  display: block;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const PlaylistName = styled.span`
  display: inline-block;
  margin-left: 1.2em;
`;

const AddArtworkModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const playlists = useSelector((state) => state.playlists);
  const params = useSelector((state) => state.app.modalParams);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        await dispatch(fetchPlaylists(user?.data?.user?.id));
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.data?.user?.id]);

  const handlePlaylistClick = useCallback(async (playlistId) => {
    try {
      const endpoint = `/users/${user.data?.id}/playlists/${playlistId}`;
      const { success, status } = await bdReq.post(endpoint, { artworkId: params.artworkId });

      if (!success) {
        if (status === 400) {
          dispatch(queueNotification({ type: 'ERROR', message: 'Artwork is already in playlist!' }));
          closeModal();
          return;
        }

        dispatch(queueNotification({ type: 'ERROR', message: 'Could not add artwork to playlist. Try again later.' }));
        closeModal();
        return;
      }

      dispatch(queueNotification({ type: 'SUCCESS', message: 'Artwork has been added to playlist!' }));
      dispatch(fetchPlaylists(user.data?.user?.id));
      closeModal();
    } catch (e) {
      dispatch(queueNotification({ type: 'ERROR', message: e.message }));
      closeModal();
    }
  });

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <h3>Add to Playlist</h3>
          <span>Select the playlist you would like to add this artwork to.</span>
          <PlaylistList>
            {playlists.data?.map((playlist) => (
              <PlaylistItem key={playlist.id}>
                <PlaylistLink onClick={() => handlePlaylistClick(playlist.id)}>
                  {playlist.isFavorites ? <Star size={25} /> : <Filing size={25} />}
                  <PlaylistName>{playlist.name}</PlaylistName>
                </PlaylistLink>
              </PlaylistItem>
            ))}
          </PlaylistList>
        </>
      )}
    </Wrapper>
  );
};

AddArtworkModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default AddArtworkModal;
