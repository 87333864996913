import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import { Plus, X, Play } from 'emotion-icons/boxicons-regular';

import {
  Wrapper,
  ArtworkLink,
  Preview,
  VideoPlayer,
  ArtTitle,
  ArtistName,
  OverlayWrapper,
  AddButton,
  DeleteButton,
  PlayButton
} from './styles';

import { openModal } from '../../store/app/actions';

const ArtworkPreview = ({
  artwork,
  artist,
  playlist,
  onRemoveClick,
  editable,
  playable,
  previewOrientation,
  showMetadata,
  list
}) => {
  const videoElement = useRef(null);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [hls] = useState(new Hls({}));
  const [manifestLoaded, setManifestLoaded] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  // Setup HLS
  useEffect(() => {
    if (Hls.isSupported()) {
      hls.loadSource(artwork?.media?.video?.hls);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        setManifestLoaded(true);
      });
      hls.on(Hls.Events.MEDIA_ATTACHED, async () => {
        try {
          await videoElement.current.play();
        } catch {
          return;
        }
        setVideoPlaying(true);
      });
    }
  }, []);

  // Play/Pause Video
  useEffect(() => {
    if (active && manifestLoaded) {
      const playVideo = async () => {
        hls.attachMedia(videoElement.current);
      };
      playVideo();
    }

    if (!active) {
      videoElement.current.pause();
      videoElement.current.currentTime = 0;
      hls.detachMedia();
      setVideoPlaying(false);
    }
  }, [active]);

  const handleAddArtwork = useCallback(() => {
    dispatch(openModal({ modal: 'ADD_ARTWORK', params: { artworkId: artwork?.id } }));
  });

  const handlePlayArtwork = useCallback(() => {
    dispatch(openModal({ modal: 'PLAY_ARTWORK', params: { artworkId: artwork?.id, playlistId: playlist?.id } }));
  });

  return (
    <Wrapper
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      orientation={previewOrientation}
      list={list}
    >
      <ArtworkLink to={`/artwork/${artwork.id}`} orientation={previewOrientation}>
        <Preview
          bgImage={artwork?.media?.image?.low?.[artwork?.orientation === 'both' ? 'landscape' : artwork?.orientation]}
          orientation={previewOrientation}
        >
          <VideoPlayer ref={videoElement} visible={videoPlaying} orientation={previewOrientation} loop muted />
        </Preview>
      </ArtworkLink>
      {showMetadata && (
        <>
          <ArtTitle>{artwork.name}</ArtTitle>
          <ArtistName>{`${artist.givenName} ${artist.surname}`}</ArtistName>
        </>
      )}
      <OverlayWrapper visible={active} orientation={previewOrientation}>
        <AddButton icon={Plus} size="30" p={8} onClick={handleAddArtwork} />
        {editable && <DeleteButton icon={X} size="30" p={8} onClick={() => onRemoveClick(artwork?.id)} />}
        {playable && <PlayButton icon={Play} size="30" p={8} onClick={handlePlayArtwork} />}
      </OverlayWrapper>
    </Wrapper>
  );
};

ArtworkPreview.propTypes = {
  artwork: PropTypes.object.isRequired,
  artist: PropTypes.object,
  playlist: PropTypes.object,
  onRemoveClick: PropTypes.func,
  editable: PropTypes.bool,
  playable: PropTypes.bool,
  previewOrientation: PropTypes.string,
  showMetadata: PropTypes.bool,
  list: PropTypes.bool
};

ArtworkPreview.defaultProps = {
  playlist: null,
  artist: null,
  editable: false,
  playable: false,
  previewOrientation: 'horizontal',
  showMetadata: true,
  list: false,
  onRemoveClick: () => {}
};

export default ArtworkPreview;
