import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION, QUEUE_NOTIFICATION, DEQUEUE_NOTIFICATION } from './constants';

const initialState = { data: [], visible: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUEUE_NOTIFICATION:
      return { ...state, data: [...state.data, action.payload] };
    case DEQUEUE_NOTIFICATION:
      return { ...state, data: state.data.slice(1) };
    case OPEN_NOTIFICATION:
      return { ...state, visible: true };
    case CLOSE_NOTIFICATION:
      return { ...state, visible: false };
    default:
      return state;
  }
};

export default reducer;
