import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import Loader from '../../components/Loader';
import PlaylistPreview from '../../components/PlaylistPreview';
import Button from '../../components/Button';

import { fetchPlaylists, createPlaylist } from '../../store/playlists/actions';
import { CREATE_PLAYLIST_SUCCESS, CREATE_PLAYLIST_FAILURE } from '../../store/playlists/constants';
import { queueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div``;

const MyPlaylists = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const playlists = useSelector((state) => state.playlists);
  const [loading, setLoading] = useState(true);
  const handleCreatePlaylist = useCallback(async () => {
    // TODO: Make this a form in a modal
    const playlistName = prompt('Playlist name', 'New Playlist');

    if (playlistName === null) return;

    if (playlistName === '') {
      dispatch(queueNotification({ type: 'ERROR', message: 'Playlist name is required.' }));
      return;
    }

    const result = await dispatch(createPlaylist(user?.data?.user?.id, { playlistName }));

    if (result.type === CREATE_PLAYLIST_SUCCESS) {
      setLoading(true);
      await dispatch(fetchPlaylists(user?.data?.user?.id));
      setLoading(false);
    }

    if (result.type === CREATE_PLAYLIST_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Failed to create playlist!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        await dispatch(fetchPlaylists(user?.data?.user?.id));
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.data?.user?.id]);

  const renderPlaylists = () => {
    return playlists.data.map((playlist) => (
      <PlaylistPreview
        key={playlist.id}
        playlist={playlist}
        userId={user?.data?.user?.id}
        galleryUserId={playlist.userId}
        pageName={window.location.pathname}
        imageUrl={playlist.imageUrl ? playlist?.imageUrl?.images?.avatar?.low : null}
        editable
        inList
      />
    ));
  };

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <Button small onClick={handleCreatePlaylist}>
            Create Collection
          </Button>
          <div>{renderPlaylists()}</div>
        </>
      )}
    </Wrapper>
  );
};

export default MyPlaylists;
