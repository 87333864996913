export default function getEnvName() {
  const host = location.host;
  if (/(localhost|127\.0\.0\.1|0\.0\.0\.0)/.test(host)) {
    return 'dev';
  } else if (/(\.io)/.test(host)) {
    return 'staging';
  } else if (/(\.com)/.test(host)) {
    return 'production';
  }
  return null;
}
