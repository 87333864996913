import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Flex } from 'reflexbox';

import { Form, TextInput, TextArea, Dropdown, Switch, SubmitButton } from '../../components/Form';

import { uploadArtwork, uploadArtworkCancel } from '../../store/newArtwork/actions';
import { queueNotification } from '../../store/notifications/actions';

import ProgressBox from '../../components/ProgressBox';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Label = styled.label`
  margin-bottom: 0.5em;
  display: inline-block;
`;

const NewArtwork = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const newArtwork = useSelector((state) => state.newArtwork);
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    artistFirstName: '',
    artistLastName: '',
    artworkName: '',
    artworkAbout: '',
    artworkEdition: '',
    artworkPricing: '',
    artworkOrientation: 'landscape',
    artworkVisiblity: 'private'
  });

  const uploadFile = async (file) => {
    if (newArtwork.uploading) return;
    dispatch(uploadArtwork(file));
  };

  useEffect(() => {
    if (newArtwork.file) {
      uploadFile(newArtwork.file);
    }
  }, [newArtwork.file]);

  useEffect(() => {
    if (newArtwork.aborted) {
      history.goBack();
    }
  }, [newArtwork.aborted]);

  const handleCancelUpload = useCallback(() => {
    dispatch(uploadArtworkCancel());
    dispatch(queueNotification({ type: 'INFO', message: 'Upload Canceled!' }));
  });

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
  });

  return (
    <Wrapper>
      <Flex flexWrap="wrap">
        <Box width={[1, 3 / 7, 3 / 7]} pr={20}>
          <ProgressBox
            current={newArtwork?.progress?.current}
            total={newArtwork?.progress?.total}
            name={newArtwork?.file?.name}
            height="100%"
            onCancel={handleCancelUpload}
          />
        </Box>
        <Box width={[1, 4 / 7, 4 / 7]}>
          <Form onSubmit={handleFormSubmit}>
            <Flex flexWrap="wrap">
              <Box width={1}>
                <Label>Artist First Name</Label>
                <TextInput name="artistFirstName" placeholder="Enter First Name" onChange={handleInputChange} />
              </Box>
              <Box width={1}>
                <Label>Artist Last Name</Label>
                <TextInput name="artistLastName" placeholder="Enter Last Name" onChange={handleInputChange} />
              </Box>
              <Box width={1}>
                <Label>Artwork Name</Label>
                <TextInput name="artworkName" placeholder="My Best Work Yet!" onChange={handleInputChange} />
              </Box>
              <Box width={1}>
                <Label>About The Artwork</Label>
                <TextArea
                  rows="6"
                  name="artworkName"
                  placeholder="Tell us a bit about this piece!"
                  onChange={handleInputChange}
                />
              </Box>
              <Box width={1}>
                <Label>Edition</Label>
                <TextInput name="artworkEdition" placeholder="[1-99]" type="number" onChange={handleInputChange} />
              </Box>
              <Box width={1}>
                <Label>Pricing</Label>
                <TextInput name="artworkPricing" placeholder="[$$]" type="number" onChange={handleInputChange} />
              </Box>
              <Box width={1}>
                <Label>Orientation</Label>
                <Dropdown
                  name="artworkOrientation"
                  defaultValue="landscape"
                  options={[
                    { label: 'Portrait', value: 'portrait' },
                    { label: 'Landscape', value: 'landscape' },
                    { label: 'Both', value: 'both' }
                  ]}
                  onChange={handleInputChange}
                />
              </Box>
              <Box width={1}>
                <Label>Visibility</Label>
                <Dropdown
                  name="artworkVisibility"
                  defaultValue="private"
                  options={[{ label: 'Private', value: 'private' }]}
                  onChange={handleInputChange}
                />
              </Box>
              <Box width={1}>
                <SubmitButton submitting={submitting}>Save Changes</SubmitButton>
              </Box>
            </Flex>
          </Form>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default NewArtwork;
