import React, { useCallback, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import bdAuth from '../../../@packages/@bd-uikit-web/js-helpers/bdAuth';

import Button from '../../components/Button';
import { Form, TextInput, SubmitButton } from '../../components/Form';
import {
  Wrapper,
  Logo,
  Heading,
  SignupContainer,
  InputWrapper,
  ButtonWrapper,
  OrDivider,
  FbIcon,
  SignupOption
} from './styles';

import { authenticate } from '../../store/app/actions';
import { AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE } from '../../store/app/constants';
import { queueNotification } from '../../store/notifications/actions';

import colors from '../../util/colors';
import logo from '../../assets/images/blackdove-logo.png';
import fbIcon from '../../assets/images/icons/facebook.png';

const useQuery = () => {
  const location = useLocation().search;
  return new URLSearchParams(location);
};

const LoginPage = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ username: '', password: '' });
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));
  const handleLoginSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    if (formState.username.length === 0 || formState.password.length === 0) {
      dispatch(queueNotification({ type: 'ERROR', message: 'You must enter an email and password.' }));
      setSubmitting(false);
      return;
    }

    const result = await dispatch(authenticate(formState));

    if (result.type === AUTHENTICATE_SUCCESS) {
      bdAuth._storeToken(result.payload);

      if (query.get('flow') === 'subscribe') {
        history.push('/account/subscriptions');
      } else {
        history.push('/');
      }
    }

    if (result.type === AUTHENTICATE_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Login failed!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      <Logo src={logo} alt="Blackdove" />
      <Heading>The most efficient way to enjoy digital art.</Heading>
      <SignupContainer>
        <Form onSubmit={handleLoginSubmit}>
          <InputWrapper>
            <TextInput name="username" type="email" placeholder="Email" onChange={handleInputChange} />
            <TextInput name="password" type="password" placeholder="Password" onChange={handleInputChange} />
          </InputWrapper>
          <ButtonWrapper>
            <SubmitButton submitting={submitting} block round>
              Log In
            </SubmitButton>
            <OrDivider>Or</OrDivider>
            <Button
              to="/"
              bgColor={colors.facebookBlue}
              bgHoverColor={colors.facebookBlueLighter}
              disabled={submitting}
              block
              round
            >
              <FbIcon src={fbIcon} alt="" />
              Log In With Facebook
            </Button>
          </ButtonWrapper>
        </Form>
      </SignupContainer>
      <SignupOption>
        Don&lsquo;t have an account? <Link to="/signup?flow=subscribe">Signup</Link>
      </SignupOption>
    </Wrapper>
  );
};

export default LoginPage;
