import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';

import countries from '@bd-uikit-web/data/countries.json';
import states from '@bd-uikit-web/data/us-states.json';
import genders from '@bd-uikit-web/data/genders.json';
import months from '@bd-uikit-web/data/months.json';

import Loader from '../../components/Loader';
import { PageTitle } from '../../components/Typography';
import { Label, Form, TextInput, Dropdown, SubmitButton } from '../../components/Form';

import { updateUser, fetchUser } from '../../store/user/actions';
import { UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } from '../../store/user/constants';
import { queueNotification } from '../../store/notifications/actions';

import { generateYears, generateDays } from '../../util/dateHelpers';

const Wrapper = styled.div``;

const EditAccountPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [days] = useState(generateDays());
  const [years] = useState(generateYears());
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    country: '',
    state: '',
    day: '',
    month: '',
    year: '',
    phoneNumber: ''
  });

  useEffect(() => {
    if (user?.data?.user?.id) {
      setLoading(false);
      const userData = user?.data?.user;
      const userDob = new Date(Date.parse(userData?.dateOfBirth));
      setFormState({
        firstName: userData.givenName,
        lastName: userData.surname,
        email: userData.email,
        gender: userData.gender,
        country: userData.country,
        state: userData.state,
        day: userDob?.getDate() + 1,
        month: userDob?.getMonth() + 1,
        year: userDob?.getFullYear(),
        phoneNumber: userData?.phoneNumber
      });
    }
  }, [user]);

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleFormSubmit = useCallback(async (e) => {
    try {
      e.preventDefault();
      if (submitting) return;
      setSubmitting(true);

      const params = {
        givenName: formState.firstName,
        surname: formState.lastName,
        email: formState.email,
        gender: formState.gender,
        country: formState.country,
        state: formState.state,
        dateOfBirth: `${formState.year}-${formState.month}-${formState.day}`,
        phoneNumber: formState.phoneNumber
      };

      const result = await dispatch(updateUser(user?.data?.user?.id, params));

      if (result.type === UPDATE_USER_SUCCESS) {
        await dispatch(fetchUser());
        dispatch(queueNotification({ type: 'SUCCESS', message: 'Your details have been updated.' }));
        history.push('/account');
      }

      if (result.type === UPDATE_USER_FAILURE) {
        const errorMessage = typeof result.errors === 'string' ? result.errors : 'Could not update your details!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        setSubmitting(false);
      }
    } catch (err) {
      const errorMessage = typeof err.message === 'string' ? err.message : 'Could not update user!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  const userData = user?.data?.user;
  const userDob = new Date(Date.parse(userData?.dateOfBirth));

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>Edit Profile</PageTitle>
          <Form onSubmit={handleFormSubmit}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1 / 2]} pr={[0, 10]}>
                <Label>First Name</Label>
                <TextInput
                  name="firstName"
                  placeholder="First Name"
                  defaultValue={userData?.givenName}
                  onChange={handleInputChange}
                />
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 10]}>
                <Label>Last Name</Label>
                <TextInput
                  name="lastName"
                  placeholder="Last Name"
                  defaultValue={userData?.surname}
                  onChange={handleInputChange}
                />
              </Box>
            </Flex>
            <Flex flexWrap="wrap">
              <Box width={[1, 1 / 2]} pr={[0, 10]}>
                <Label>E-mail</Label>
                <TextInput name="email" placeholder="Email" defaultValue={userData?.email} disabled />
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 10]}>
                <Label>Gender</Label>
                <Dropdown
                  name="gender"
                  defaultValue={userData?.gender}
                  options={genders.map((gender) => ({ label: gender, value: gender }))}
                  onChange={handleInputChange}
                />
              </Box>
            </Flex>
            <Flex flexWrap="wrap">
              <Box width={[1 / 2]} pr={[10]}>
                <Label>Country</Label>
                <Dropdown
                  name="country"
                  placeholder="Select a Country"
                  defaultValue={userData?.country}
                  options={countries.map((country) => ({ label: country.name, value: country.name }))}
                  onChange={handleInputChange}
                />
              </Box>
              <Box width={[1 / 2]} pl={[10]}>
                {formState.country === 'United States' && (
                  <>
                    <Label>State</Label>
                    <Dropdown
                      name="state"
                      placeholder="Select a State"
                      defaultValue={userData?.state}
                      options={states.map((state) => ({ label: state.name, value: state.name }))}
                      onChange={handleInputChange}
                    />
                  </>
                )}
              </Box>
            </Flex>
            <Flex flexWrap="wrap">
              <Box width={[1 / 2]} pr={[10]}>
                <Label>Date of Birth</Label>
                <Flex flexWrap="wrap">
                  <Box width={[1 / 3]} pr={10}>
                    <Dropdown
                      name="day"
                      defaultValue={(userDob?.getDate() + 1).toString()}
                      options={days.map((day) => ({ label: day.toString(), value: day.toString() }))}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box width={[1 / 3]}>
                    <Dropdown
                      name="month"
                      defaultValue={(userDob?.getMonth() + 1).toString()}
                      options={Object.keys(months).map((key) => ({
                        label: months[key].name,
                        value: months[key].number.toString()
                      }))}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box width={[1 / 3]} pl={10}>
                    <Dropdown
                      name="year"
                      defaultValue={userDob?.getFullYear().toString()}
                      options={years.map((year) => ({ label: year.toString(), value: year.toString() }))}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Flex>
              </Box>
              <Box width={[1 / 2]} pl={[10]}>
                <Label>Mobile Number</Label>
                <TextInput
                  name="phoneNumber"
                  placeholder="Phone Number"
                  defaultValue={userData?.phoneNumber}
                  onChange={handleInputChange}
                />
              </Box>
            </Flex>
            <SubmitButton submitting={submitting}>Save Changes</SubmitButton>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

export default EditAccountPage;
