import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Form, SubmitButton } from '../../Form';

import { createSubscription } from '../../../store/subscription/actions';
import { CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_FAILURE } from '../../../store/subscription/constants';
import { createCard } from '../../../store/cards/actions';
import { CREATE_CARD_FAILURE } from '../../../store/cards/constants';
import { queueNotification } from '../../../store/notifications/actions';

const Wrapper = styled.div``;

const CardElementWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseLighter};
  margin-bottom: 1em;
`;

const SubscribeModal = ({ closeModal }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [submitting, setSubmitting] = useState(false);
  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting || !stripe || !elements) return;
    setSubmitting(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const stripeResult = await stripe.createToken(cardElement, { email: user?.data?.user?.email });
      const cardResult = await dispatch(createCard(user?.data?.user?.id, stripeResult.token));

      if (cardResult.type === CREATE_CARD_FAILURE) {
        const errorMessage = typeof cardResult.errors === 'string' ? cardResult.errors : 'Failed to save card!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        setSubmitting(false);
      }

      const params = {
        stripeToken: stripeResult.token.id,
        stripeEmail: stripeResult.token.email
      };

      const result = await dispatch(
        createSubscription(user?.data?.user?.id, 'h23r23wrqw2ur7yq3tq8w2qwu-8q2r92qhf527nt', params)
      );

      if (result.type === CREATE_SUBSCRIPTION_SUCCESS) {
        closeModal();
        dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully subscribed!' }));
        setSubmitting(false);
      }

      if (result.type === CREATE_SUBSCRIPTION_FAILURE) {
        const errorMessage = typeof result.errors === 'string' ? result.errors : 'Could not subscribe!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        setSubmitting(false);
        closeModal();
      }
    } catch (error) {
      dispatch(queueNotification({ type: 'ERROR', message: error.message }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      <Form onSubmit={handleFormSubmit}>
        <h2>Subscribe to Blackdove</h2>
        <CardElementWrapper>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '20px',
                  color: 'white',
                  lineHeight: '50px',
                  paddingTop: '20px'
                }
              }
            }}
          />
        </CardElementWrapper>
        <SubmitButton submitting={submitting}>Subscribe</SubmitButton>
      </Form>
    </Wrapper>
  );
};

SubscribeModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default SubscribeModal;
