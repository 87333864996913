import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { uploadArtworkAddFile } from '../../store/newArtwork/actions';

import Uploader from '../../components/Uploader';

const Wrapper = styled.div``;

const PageHeading = styled.h1`
  margin-top: 0;
`;

const ArtworkList = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.light};
  padding: 20px;
`;

const NoArtwork = styled.span`
  display: block;
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const NoArtworkInfo = styled.span`
  display: block;
  font-size: 0.8em;
`;

const ManageArtwork = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUpload = useCallback((file) => {
    dispatch(uploadArtworkAddFile(file));
    history.push('/manage-artwork/new');
  });

  return (
    <Wrapper>
      <PageHeading>Upload Artworks</PageHeading>
      <Uploader
        allowedTypes={['video/mp4', 'video/quicktime', 'image/png', 'image/jpeg']}
        maxFileSizeMB={10000}
        onUpload={handleUpload}
      />
      <ArtworkList>
        <NoArtwork>No Artwork Yet</NoArtwork>
        <NoArtworkInfo>You don&lsquo;t have any artwork yet. Your uploaded artwork will appear here.</NoArtworkInfo>
      </ArtworkList>
    </Wrapper>
  );
};

export default ManageArtwork;
