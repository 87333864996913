import { FETCH_PRICE_TIERS_REQUEST, FETCH_PRICE_TIERS_SUCCESS, FETCH_PRICE_TIERS_FAILURE } from './constants';

const initialState = { data: [], loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRICE_TIERS_REQUEST:
      return { ...state, loading: true };
    case FETCH_PRICE_TIERS_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_PRICE_TIERS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
