import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Flex } from 'reflexbox';

import { PageTitle } from '../../components/Typography';
import { Form, TextInput, Dropdown, Switch, SubmitButton } from '../../components/Form';

import { createDevice } from '../../store/devices/actions';
import { CREATE_DEVICE_SUCCESS, CREATE_DEVICE_FAILURE } from '../../store/devices/constants';
import { queueNotification } from '../../store/notifications/actions';

import { readableToMs } from '../../util/dateHelpers';

const Wrapper = styled.div``;

const Label = styled.label`
  margin-bottom: 0.5em;
  display: inline-block;
`;

const NewDevice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    uid: '',
    name: '',
    intervalValue: '',
    intervalType: 'minutes',
    infinite: false,
    mute: false,
    orientation: 'portrait'
  });

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    const params = {
      uid: formState.uid,
      name: formState.name,
      interval: formState.infinite ? 0 : readableToMs({ value: formState.intervalValue, type: formState.intervalType }),
      mute: formState.mute,
      orientation: formState.orientation
    };

    if (params.uid.length === 0 || params.name.length === 0 || !['number', 'string'].includes(typeof params.interval)) {
      dispatch(queueNotification({ type: 'ERROR', message: 'Required fields are missing.' }));
      setSubmitting(false);
      return;
    }

    const result = await dispatch(createDevice(user?.data?.user?.id, params));

    if (result.type === CREATE_DEVICE_SUCCESS) {
      history.push('/devices');
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully created device.' }));
    }

    if (result.type === CREATE_DEVICE_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Device could not be added!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      <PageTitle>Add Device</PageTitle>
      <Form onSubmit={handleFormSubmit}>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} pr={[0, 0, 15]}>
            <Label>Device ID</Label>
            <TextInput name="uid" placeholder="H3IFHD" onChange={handleInputChange} />
          </Box>
          <Box width={[1, 1, 1 / 2]} pl={[0, 0, 15]}>
            <Label>Device Name</Label>
            <TextInput name="name" placeholder="Living Room" onChange={handleInputChange} />
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} pr={[0, 0, 15]}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 1 / 3]} pr={[0, 0, 15]}>
                <Label>Change art every...</Label>
                <TextInput name="intervalValue" placeholder="30" onChange={handleInputChange} />
              </Box>
              <Box width={[1, 1, 1 / 3]} pl={[0, 0, 15]} pr={[0, 0, 15]}>
                <Label>Unit</Label>
                <Dropdown
                  name="intervalType"
                  defaultValue="minutes"
                  options={[
                    { label: 'Seconds', value: 'seconds' },
                    { label: 'Minutes', value: 'minutes' },
                    { label: 'Hours', value: 'hours' }
                  ]}
                  onChange={handleInputChange}
                />
              </Box>
              <Box with={[1, 1, 1 / 3]} pl={[0, 0, 15]}>
                <Label>Don&lsquo;t switch art</Label>
                <Switch name="infinite" onChange={handleInputChange} />
              </Box>
            </Flex>
          </Box>
          <Box width={[1, 1, 1 / 2]} pl={[0, 0, 15]}>
            <Label>Screen Orientation</Label>
            <Dropdown
              name="orientation"
              defaultValue="portrait"
              options={[
                { label: 'Portrait', value: 'portrait' },
                { label: 'Landscape', value: 'landscape' }
              ]}
              onChange={handleInputChange}
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box width={1}>
            <Label>Mute</Label>
            <Switch name="mute" onChange={handleInputChange} />
          </Box>
        </Flex>
        <SubmitButton submitting={submitting}>Create Device</SubmitButton>
      </Form>
    </Wrapper>
  );
};

export default NewDevice;
