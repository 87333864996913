import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import { FETCH_STAFF_REQUEST, FETCH_STAFF_SUCCESS, FETCH_STAFF_FAILURE } from './constants';

const fetchStaffRequest = () => ({ type: FETCH_STAFF_REQUEST });
const fetchStaffSuccess = (payload) => ({ type: FETCH_STAFF_SUCCESS, payload });
const fetchStaffFailure = (errors) => ({ type: FETCH_STAFF_FAILURE, errors });

export const fetchStaff = () => async (dispatch) => {
  dispatch(fetchStaffRequest());
  try {
    const response = await bdReq.get(`/staff/artworks`);
    if (response.success) {
      return dispatch(fetchStaffSuccess(response.body));
    }
    return dispatch(fetchStaffFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchStaffFailure(e));
  }
};
