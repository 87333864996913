import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import {
  FETCH_DEVICES_REQUEST,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,
  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  CREATE_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE
} from './constants';

const fetchDevicesRequest = () => ({ type: FETCH_DEVICES_REQUEST });
const fetchDevicesSuccess = (payload) => ({ type: FETCH_DEVICES_SUCCESS, payload });
const fetchDevicesFailure = (errors) => ({ type: FETCH_DEVICES_FAILURE, errors });

export const fetchDevices = (userId) => async (dispatch) => {
  dispatch(fetchDevicesRequest());
  try {
    const response = await bdReq.get(`/users/${userId}/devices`);
    if (response.success) {
      return dispatch(fetchDevicesSuccess(response.body));
    }
    return dispatch(fetchDevicesFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchDevicesFailure(e));
  }
};

const createDeviceRequest = () => ({ type: CREATE_DEVICE_REQUEST });
const createDeviceSuccess = (payload) => ({ type: CREATE_DEVICE_SUCCESS, payload });
const createDeviceFailure = (errors) => ({ type: CREATE_DEVICE_FAILURE, errors });

export const createDevice = (userId, params) => async (dispatch) => {
  dispatch(createDeviceRequest());
  try {
    const response = await bdReq.post(`/users/${userId}/devices`, params);
    if (response.success) {
      return dispatch(createDeviceSuccess(response.body));
    }
    return dispatch(createDeviceFailure(response.body.message));
  } catch (e) {
    return dispatch(createDeviceFailure(e));
  }
};

const updateDeviceRequest = () => ({ type: UPDATE_DEVICE_REQUEST });
const updateDeviceSuccess = (payload) => ({ type: UPDATE_DEVICE_SUCCESS, payload });
const updateDeviceFailure = (errors) => ({ type: UPDATE_DEVICE_FAILURE, errors });

export const updateDevice = (userId, deviceId, params) => async (dispatch) => {
  dispatch(updateDeviceRequest());
  try {
    const response = await bdReq.put(`/users/${userId}/devices/${deviceId}`, params);
    if (response.success) {
      return dispatch(updateDeviceSuccess(response.body));
    }
    return dispatch(updateDeviceFailure(response.body.message));
  } catch (e) {
    return dispatch(updateDeviceFailure(e));
  }
};

const deleteDeviceRequest = () => ({ type: DELETE_DEVICE_REQUEST });
const deleteDeviceSuccess = (payload) => ({ type: DELETE_DEVICE_SUCCESS, payload });
const deleteDeviceFailure = (errors) => ({ type: DELETE_DEVICE_FAILURE, errors });

export const deleteDevice = (userId, deviceId) => async (dispatch) => {
  dispatch(deleteDeviceRequest());
  try {
    const response = await bdReq.delete(`/users/${userId}/devices/${deviceId}`);
    if (response.success) {
      return dispatch(deleteDeviceSuccess(response.body));
    }
    return dispatch(deleteDeviceFailure(response.body.message));
  } catch (e) {
    return dispatch(deleteDeviceFailure(e));
  }
};
