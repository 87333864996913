import Raven from 'raven-js';

const RAVEN_IDS = {
  'bd-staff-portal': 'https://9d8af64fb40844e582db700ef3649044@sentry.io/164946',
  'bd-web-app': 'https://5626b0687f584913a92846d52e08ae7a@sentry.io/164947',
  'bd-reseller-portal': 'https://2995fbdbf8904249abc55c7f2189429f@sentry.io/126882',
  'bd-accounts-portal': 'https://1e5dcfc841fc4e218ea85f18656a31da@sentry.io/175467',
};

export default {

  install(appId) {
    const devHosts = ['localhost', '127.0.0.1', '.+.blackdove.dev'];
    const ravenId = RAVEN_IDS[appId];
    let devMatch = false;

    for (const devHost of devHosts) {
      devMatch = new RegExp(devHost).test(location.hostname);
      if (devMatch) {
        break;
      }
    }

    if (!devMatch && ravenId) {
      Raven.config(ravenId).install();
    }
  },

  setUserContext(user) {
    Raven.setUserContext(user);
  },

  captureException(err) {
    Raven.captureException(err);
  },

};
