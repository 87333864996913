import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Flex } from 'reflexbox';

import { PageTitle } from '../../components/Typography';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import { Form, Label, TextInput, Dropdown, Switch, SubmitButton } from '../../components/Form';

import { fetchDevices, updateDevice, deleteDevice } from '../../store/devices/actions';
import {
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE
} from '../../store/devices/constants';
import { queueNotification } from '../../store/notifications/actions';

import { msToReadable, readableToMs } from '../../util/dateHelpers';

const Wrapper = styled.div``;

const DeleteButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.error};

  &:hover {
    background: ${({ theme }) => theme.colors.error};
  }
`;

const EditDevice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    uid: '',
    name: '',
    intervalValue: '',
    intervalType: 'minutes',
    infinite: false,
    mute: false,
    orientation: 'portrait'
  });

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        const result = await dispatch(fetchDevices(user?.data?.user?.id));
        const device = result?.payload?.find((item) => item.id === id);
        const readable = msToReadable(device.interval);
        setFormState({
          uid: device.uid,
          name: device.name,
          intervalValue: readable.value,
          intervalType: readable.type,
          infinite: readable.value === 0,
          mute: device.mute,
          orientation: device.orientation
        });
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.data?.user?.id]);

  const handleDeleteDevice = useCallback(async () => {
    const result = await dispatch(deleteDevice(user?.data?.user?.id, id));

    if (result.type === DELETE_DEVICE_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully removed device.' }));
      history.push('/devices');
    }

    if (result.type === DELETE_DEVICE_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Device could not be deleted!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    const params = {
      uid: formState.uid,
      name: formState.name,
      interval: formState.infinite ? 0 : readableToMs({ value: formState.intervalValue, type: formState.intervalType }),
      mute: formState.mute,
      orientation: formState.orientation
    };

    if (params.uid.length === 0 || params.name.length === 0 || !['number', 'string'].includes(typeof params.interval)) {
      dispatch(queueNotification({ type: 'ERROR', message: 'Required fields are missing.' }));
      setSubmitting(false);
      return;
    }

    const result = await dispatch(updateDevice(user?.data?.user?.id, id, params));

    if (result.type === UPDATE_DEVICE_SUCCESS) {
      history.push('/devices');
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully updated device.' }));
    }

    if (result.type === UPDATE_DEVICE_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Device could not be updated!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>Edit Device</PageTitle>
          <Form onSubmit={handleFormSubmit}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 1 / 2]} pr={[0, 0, 15]}>
                <Label>Device ID</Label>
                <TextInput name="uid" placeholder="H3IFHD" value={formState.uid} onChange={handleInputChange} />
              </Box>
              <Box width={[1, 1, 1 / 2]} pl={[0, 0, 15]}>
                <Label>Device Name</Label>
                <TextInput name="name" placeholder="Living Room" value={formState.name} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 1 / 2]} pr={[0, 0, 15]}>
                <Flex flexWrap="wrap">
                  <Box width={[1, 1, 1 / 3]} pr={[0, 0, 15]}>
                    <Label>Change art every...</Label>
                    <TextInput
                      name="intervalValue"
                      placeholder="30"
                      value={formState.intervalValue}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box width={[1, 1, 1 / 3]} pl={[0, 0, 15]} pr={[0, 0, 15]}>
                    <Label>Unit</Label>
                    <Dropdown
                      name="intervalType"
                      defaultValue={formState.intervalType}
                      options={[
                        { label: 'Seconds', value: 'seconds' },
                        { label: 'Minutes', value: 'minutes' },
                        { label: 'Hours', value: 'hours' }
                      ]}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box with={[1, 1, 1 / 3]} pl={[0, 0, 15]}>
                    <Label>Don&lsquo;t switch art</Label>
                    <Switch name="infinite" defaultValue={formState.infinite} onChange={handleInputChange} />
                  </Box>
                </Flex>
              </Box>
              <Box width={[1, 1, 1 / 2]} pl={[0, 0, 15]}>
                <Label>Screen Orientation</Label>
                <Dropdown
                  name="orientation"
                  defaultValue={formState.orientation}
                  options={[
                    { label: 'Portrait', value: 'portrait' },
                    { label: 'Landscape', value: 'landscape' }
                  ]}
                  onChange={handleInputChange}
                />
              </Box>
            </Flex>
            <Flex flexWrap="wrap">
              <Box width={1}>
                <Label>Mute</Label>
                <Switch name="mute" defaultValue={formState.mute} onChange={handleInputChange} />
              </Box>
            </Flex>
            <SubmitButton submitting={submitting}>Save Changes</SubmitButton>
            <DeleteButton ghost small onClick={handleDeleteDevice}>
              Delete Device
            </DeleteButton>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

export default EditDevice;
