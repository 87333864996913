import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';
import { Add, Search } from 'emotion-icons/ion-ios';

// TODO: bdReq and bdAuth should be extracted into a proper package
import bdReq from '@bd-uikit-web/js-helpers/bdReq';

import Loader from '../../components/Loader';
import { PageTitle } from '../../components/Typography';
import { Table, TableHead, TableBody, TableRow, HeaderCell, BodyCell } from '../../components/Table';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import { Form, Label, TextInput, Dropdown } from '../../components/Form';
import Playlist from '../../components/Playlist';

import { fetchPlaylist } from '../../store/selectedPlaylist/actions';
import { FETCH_PLAYLIST_FAILURE } from '../../store/selectedPlaylist/constants';
import { fetchFollowers, createFollower, updateFollower, deleteFollower } from '../../store/followers/actions';
import {
  FETCH_FOLLOWERS_FAILURE,
  CREATE_FOLLOWER_SUCCESS,
  CREATE_FOLLOWER_FAILURE,
  UPDATE_FOLLOWER_SUCCESS,
  UPDATE_FOLLOWER_FAILURE,
  DELETE_FOLLOWER_SUCCESS,
  DELETE_FOLLOWER_FAILURE
} from '../../store/followers/constants';
import { fetchPriceTiers } from '../../store/priceTiers/actions';
import { FETCH_PRICE_TIERS_FAILURE } from '../../store/priceTiers/constants';
import { queueNotification } from '../../store/notifications/actions';
import { updateCollectionThumbnail } from '../../store/thumbnail/actions';
import {
  UPDATE_COLLECTION_THUMBNAIL_SUCCESS,
  UPDATE_COLLECTION_THUMBNAIL_FAILURE
} from '../../store/thumbnail/constants';
import Uploader from '../../components/CustomUploader';
import { CREATE_PLAYLIST_FAILURE } from '../../store/playlists/constants';

const Wrapper = styled.div``;

const SearchWrapper = styled.div`
  position: relative;
  padding-right: 50px;
`;

const SearchButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px;
`;

const ShareButton = styled(IconButton)`
  padding: 11px;
  margin-top: 30px;
`;

const useQuery = () => {
  const location = useLocation().search;
  return new URLSearchParams(location);
};

const PlaylistPage = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const selectedPlaylist = useSelector((state) => state.selectedPlaylist);
  const followers = useSelector((state) => state.followers);
  const priceTiers = useSelector((state) => state.priceTiers);
  const [loading, setLoading] = useState(true);
  const [shareSubmitting, setShareSubmitting] = useState(false);
  const [formState, setFormState] = useState({ email: '', rights: 'READ' });
  const [searchFormState, setSearchFormState] = useState({ query: '' });
  const [searchQuery, setSearchQuery] = useState('');

  const editable = query.get('editable') === 'true';

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        await Promise.all([
          (async () => {
            const result = await dispatch(fetchPlaylist(user?.data?.user?.id, id));

            if (result.type === FETCH_PLAYLIST_FAILURE) {
              const errorMessage = typeof result.errors === 'string' ? result.errors : 'Failed to fetch playlist!';
              dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
            }
          })(),
          (async () => {
            const result = await dispatch(fetchFollowers(id));

            if (result.type === FETCH_FOLLOWERS_FAILURE) {
              const errorMessage = typeof result.errors === 'string' ? result.errors : 'Failed to fetch followers!';
              dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
            }
          })(),
          (async () => {
            const result = await dispatch(fetchPriceTiers());

            if (result.type === FETCH_PRICE_TIERS_FAILURE) {
              const errorMessage = typeof result.errors === 'string' ? result.errors : 'Failed to fetch price tiers!';
              dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
            }
          })()
        ]);
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.data?.user?.id]);

  const handleSubmitCollection = useCallback(async () => {
    try {
      const result = await bdReq.post(`/collections/${id}`);
      if (!result.success) throw new Error('Failed to submit as collection');
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Playlist submitted as a collection.' }));
    } catch (e) {
      dispatch(queueNotification({ type: 'ERROR', message: 'Failed to submit playlist as a collection.' }));
    }
  });

  const handleSearchSubmit = useCallback((e) => {
    e.preventDefault();
    setSearchQuery(searchFormState.query);
  });

  const handleShareSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (shareSubmitting) return;
    setShareSubmitting(true);

    if (formState.email.length === 0 || formState.rights.length === 0) {
      dispatch(queueNotification({ type: 'ERROR', message: 'You must enter an email and an access level.' }));
      setShareSubmitting(false);
      return;
    }

    try {
      const { success, body: users } = await bdReq.get('/users');

      if (!success) {
        dispatch(queueNotification({ type: 'ERROR', message: 'Could not fetch users.' }));
        setShareSubmitting(false);
        return;
      }

      const email = users.filter((data) => data.email === formState.email);

      if (email.length === 0) {
        dispatch(queueNotification({ type: 'ERROR', message: `Unable to share with '${formState.email}'.` }));
        setShareSubmitting(false);
        return;
      }

      const result = await dispatch(createFollower(id, { userId: email[0].id, rights: formState.rights }));

      if (result.type === CREATE_FOLLOWER_SUCCESS) {
        dispatch(queueNotification({ type: 'SUCCESS', message: `Successfully shared with ${email[0].email}!` }));
        dispatch(fetchFollowers(id));
        setFormState({ ...formState, email: '' });
        setShareSubmitting(false);
      }

      if (result.type === CREATE_FOLLOWER_FAILURE) {
        const errorMessage =
          typeof result.errors === 'string' ? result.errors : `Could not share with ${email[0].email}!`;
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        setShareSubmitting(false);
      }
    } catch (err) {
      dispatch(queueNotification({ type: 'ERROR', message: err.message }));
      setShareSubmitting(false);
    }
  });

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleSearchInputChange = useCallback((e) => {
    setSearchFormState({ query: e.target.value });
  });

  const handleUserRightsChange = useCallback(async (e, userId) => {
    const result = await dispatch(updateFollower(id, { userId, rights: e.target.value }));

    if (result.type === UPDATE_FOLLOWER_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Access level changed!' }));
      dispatch(fetchFollowers(id));
    }

    if (result.type === UPDATE_FOLLOWER_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Could not change access level!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  const handleRemoveFollower = useCallback(async (followerId) => {
    const result = await dispatch(deleteFollower(id, followerId));

    if (result.type === DELETE_FOLLOWER_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Follower successfully removed!' }));
      dispatch(fetchFollowers(id));
    }

    if (result.type === DELETE_FOLLOWER_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Follower could not be removed!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  const renderFollowers = () =>
    followers.data.map((follower) => {
      return (
        <TableRow key={follower?.user?.id}>
          <BodyCell>{`${follower?.user?.givenName} ${follower?.user?.surname}`}</BodyCell>
          <BodyCell>{follower?.user?.email}</BodyCell>
          <BodyCell>
            <Dropdown
              mb={0}
              name="right"
              defaultValue={follower?.rights}
              options={[
                { label: 'Read', value: 'READ' },
                { label: 'Edit', value: 'EDIT' }
              ]}
              onChange={(e) => handleUserRightsChange(e, follower?.user?.id)}
            />
          </BodyCell>
          <BodyCell>
            <Button small mb={0} onClick={() => handleRemoveFollower(follower?.user?.id)}>
              Remove
            </Button>
          </BodyCell>
        </TableRow>
      );
  });

  // Save image to db
  const handleUpload = useCallback( async (uploadedImage) => {
    
    if (uploadedImage == undefined || uploadedImage.errors) {
      dispatch(queueNotification({ type: 'ERROR', message: 'Failed to upload thumbnail image.'}));
    } else {
      try {
        var params = { imageId: uploadedImage.id, playlistId: id };

        const result = await dispatch(updateCollectionThumbnail(params));
        
        if (result.type === UPDATE_COLLECTION_THUMBNAIL_SUCCESS) {
          dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully upated the thumbnail' }));
        }

        if (result.type === UPDATE_COLLECTION_THUMBNAIL_FAILURE) {
          dispatch(queueNotification({ type: 'ERROR', message: result.errors }));
        }

      } catch (err) {
        dispatch(queueNotification({ type: 'ERROR', message: err.message }));
      }
    }
  })

  const submitThumbnailToCollection = useCallback (async (id) => {
    
  })

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>{selectedPlaylist?.data?.name}</PageTitle>
          <Flex flexWrap="wrap" mb={15}>
            <Box width={[1, 1, 1 / 2]}>
              <Button ghost small to={query.get('backPath')}>
                Back to Overview
              </Button>
              <Button small onClick={handleSubmitCollection}>
                Submit as Collection
              </Button>
              <Uploader 
                allowedTypes={['image/png', 'image/jpeg', 'image/jpg']}
                maxFileSizeMB={10000}
                onUpload={handleUpload}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]} pl={[0, 0, 100]}>
              <Form onSubmit={handleSearchSubmit}>
                <SearchWrapper>
                  <TextInput placeholder="Filter artwork..." onChange={handleSearchInputChange} />
                  <SearchButton type="submit" icon={Search} size="25px" bgColor="none" bgColorHover="none" />
                </SearchWrapper>
              </Form>
            </Box>
          </Flex>
          {editable && (
            <Form onSubmit={handleShareSubmit}>
              <Flex flexWrap="wrap">
                <Box width={[1, 1 / 3, 1 / 3]} pr={15}>
                  <Label>Share Collection with</Label>
                  <TextInput placeholder="Email" name="email" onChange={handleInputChange} value={formState.email} />
                </Box>
                <Box width={[1, 1 / 3, 1 / 3]} pr={15}>
                  <Label>Access Level</Label>
                  <Dropdown
                    name="rights"
                    defaultValue="READ"
                    options={[
                      { label: 'Read', value: 'READ' },
                      { label: 'Edit', value: 'EDIT' }
                    ]}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box width={[1, 1 / 3, 1 / 3]}>
                  <ShareButton type="submit" icon={Add} size="25px" />
                </Box>
              </Flex>
            </Form>
          )}
          {followers?.data?.length > 0 && editable && (
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderCell>Username</HeaderCell>
                  <HeaderCell>Email</HeaderCell>
                  <HeaderCell>Acces Level</HeaderCell>
                  <HeaderCell>Action</HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderFollowers()}</TableBody>
            </Table>
          )}
          <Playlist
            playlist={selectedPlaylist}
            userId={user?.data?.user?.id}
            backPath={query.get('backPath')}
            priceTiers={priceTiers?.data}
            searchQuery={searchQuery}
            editable={editable}
          />
        </>
      )}
    </Wrapper>
  );
};

export default PlaylistPage;
