import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { space } from 'styled-system';
import { Link, useLocation } from 'react-router-dom';
import { Easel, Filing, Tv, Images, Card } from 'emotion-icons/ion-ios';

import logo from '../../../../../assets/images/bd-logo-dove.svg';

const Wrapper = styled.div`
  ${space}
  background: ${({ theme }) => theme.colors.baseLighter};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  margin: 0;
  width: 100px;
  align-items: center;
  text-align: center;
  z-index: 3;
  overflow: hidden;
`;

const Logo = styled.img`
  display: inline-block;
  height: 1.6em;
  margin-top: 1.2em;
  margin-bottom: 2em;
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  padding-bottom: 2em;
  position: relative;
`;

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.whiteFaded)};
  transition: color 0.2s ease;
  text-decoration: none;
  font-size: 0.8em;
  position: relative;
  display: block;

  svg {
    fill: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.whiteFaded)};
    transition: fill 0.2s ease;
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary};
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity 0.5s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Navigation = () => {
  const location = useLocation();

  return (
    <Wrapper>
      <Link to="/">
        <Logo src={logo} alt="Blackdove" />
      </Link>
      <NavList>
        <NavItem>
          <StyledLink to="/" active={location.pathname === '/'}>
            <Easel height={35} />
            <span>Home</span>
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/my-playlists" active={location.pathname === '/my-playlists'}>
            <Filing height={35} />
            <span>
              My <br />
              Collections
            </span>
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/devices" active={location.pathname.split('/')[1] === 'devices'}>
            <Tv height={35} />
            <span>Devices</span>
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/manage-artwork" active={location.pathname.split('/')[1] === 'manage-artwork'}>
            <Images height={35} />
            <span>
              Manage <br />
              Artwork
            </span>
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/account" active={location.pathname.split('/')[1] === 'account'}>
            <Card height={35} />
            <span>Account</span>
          </StyledLink>
        </NavItem>
      </NavList>
    </Wrapper>
  );
};

export default Navigation;
