import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import {
  FETCH_FOLLOWERS_REQUEST,
  FETCH_FOLLOWERS_SUCCESS,
  FETCH_FOLLOWERS_FAILURE,
  CREATE_FOLLOWER_REQUEST,
  CREATE_FOLLOWER_SUCCESS,
  CREATE_FOLLOWER_FAILURE,
  UPDATE_FOLLOWER_REQUEST,
  UPDATE_FOLLOWER_SUCCESS,
  UPDATE_FOLLOWER_FAILURE,
  DELETE_FOLLOWER_REQUEST,
  DELETE_FOLLOWER_SUCCESS,
  DELETE_FOLLOWER_FAILURE
} from './constants';

const fetchFollowersRequest = () => ({ type: FETCH_FOLLOWERS_REQUEST });
const fetchFollowersSuccess = (payload) => ({ type: FETCH_FOLLOWERS_SUCCESS, payload });
const fetchFollowersFailure = (errors) => ({ type: FETCH_FOLLOWERS_FAILURE, errors });

export const fetchFollowers = (playlistId) => async (dispatch) => {
  dispatch(fetchFollowersRequest());
  try {
    const response = await bdReq.get(`/playlists/${playlistId}/followers`);
    if (response.success) {
      return dispatch(fetchFollowersSuccess(response.body));
    }
    return dispatch(fetchFollowersFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchFollowersFailure(e));
  }
};

const createFollowerRequest = () => ({ type: CREATE_FOLLOWER_REQUEST });
const createFollowerSuccess = (payload) => ({ type: CREATE_FOLLOWER_SUCCESS, payload });
const createFollowerFailure = (errors) => ({ type: CREATE_FOLLOWER_FAILURE, errors });

export const createFollower = (playlistId, params) => async (dispatch) => {
  dispatch(createFollowerRequest());
  try {
    const response = await bdReq.post(`/staff/playlists/${playlistId}/followers`, params);
    if (response.success) {
      return dispatch(createFollowerSuccess(response.body));
    }
    return dispatch(createFollowerFailure(response.body.message));
  } catch (e) {
    return dispatch(createFollowerFailure(e));
  }
};

const updateFollowerRequest = () => ({ type: UPDATE_FOLLOWER_REQUEST });
const updateFollowerSuccess = (payload) => ({ type: UPDATE_FOLLOWER_SUCCESS, payload });
const updateFollowerFailure = (errors) => ({ type: UPDATE_FOLLOWER_FAILURE, errors });

export const updateFollower = (playlistId, params) => async (dispatch) => {
  dispatch(updateFollowerRequest());
  try {
    const response = await bdReq.put(`/staff/playlists/${playlistId}/followers`, params);
    if (response.success) {
      return dispatch(updateFollowerSuccess(response.body));
    }
    return dispatch(updateFollowerFailure(response.body.message));
  } catch (e) {
    return dispatch(updateFollowerFailure(e));
  }
};

const deleteFollowerRequest = () => ({ type: DELETE_FOLLOWER_REQUEST });
const deleteFollowerSuccess = (payload) => ({ type: DELETE_FOLLOWER_SUCCESS, payload });
const deleteFollowerFailure = (errors) => ({ type: DELETE_FOLLOWER_FAILURE, errors });

export const deleteFollower = (playlistId, followerId) => async (dispatch) => {
  dispatch(deleteFollowerRequest());
  try {
    const response = await bdReq.delete(`/staff/playlists/${playlistId}/followers/${followerId}`);
    if (response.success) {
      return dispatch(deleteFollowerSuccess(response.body));
    }
    return dispatch(deleteFollowerFailure(response.body.message));
  } catch (e) {
    return dispatch(deleteFollowerFailure(e));
  }
};
