import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import { FETCH_ARTIST_REQUEST, FETCH_ARTIST_SUCCESS, FETCH_ARTIST_FAILURE } from './constants';

const fetchArtistRequest = () => ({ type: FETCH_ARTIST_REQUEST });
const fetchArtistSuccess = (payload) => ({ type: FETCH_ARTIST_SUCCESS, payload });
const fetchArtistFailure = (errors) => ({ type: FETCH_ARTIST_FAILURE, errors });

export const fetchArtist = (artistId) => async (dispatch) => {
  dispatch(fetchArtistRequest());
  try {
    const response = await bdReq.get(`/artists/${artistId}`);
    if (response.success) {
      return dispatch(fetchArtistSuccess(response.body));
    }
    return dispatch(fetchArtistFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchArtistFailure(e));
  }
};
