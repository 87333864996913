import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { ArrowDropleft, ArrowDropright } from 'emotion-icons/ion-ios';
import { v4 as uuid } from 'uuid';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import IconButton from '../IconButton';

const Wrapper = styled.div`
  width: 100%;
  height: 360px;
  padding: 20px;
  display: flex;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 360px;
  position: relative;
  overflow: hidden;
`;

const CarouselItem = styled.div`
  width: ${({ slideWidth }) => slideWidth}px;
  height: 360px;
  position: absolute;
  top: 0;
  left: ${({ slideOffset }) => slideOffset}px;
  transition: ${({ animate }) => (animate ? 'left 0.45s ease' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const getBreakpoint = (screenWidth, breakpoints) => {
  if (screenWidth >= breakpoints.large.width) {
    return 'large';
  }

  if (screenWidth >= breakpoints.medium.width) {
    return 'medium';
  }

  return 'small';
};

const Carousel = ({ items, renderItem, breakpoints }) => {
  const theme = useTheme();
  const wrapperRef = useRef(null);
  const { width } = useWindowDimensions();
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [slidesVisible, setSlidesVisible] = useState(breakpoints[getBreakpoint(width, breakpoints)].slidesToShow);
  const [slideState, setSlideState] = useState({
    slides: items.map((item) => ({
      id: uuid(),
      item
    })),
    currentSlide: 0,
    reordered: false,
    animate: false,
    animating: false,
    navDirection: null
  });

  useEffect(() => {
    setCarouselWidth(wrapperRef.current.clientWidth);
    setSlideState({ ...slideState, slides: slideState.slides.map((slide) => ({ ...slide, id: uuid() })) });

    const handleResize = () => {
      setCarouselWidth(wrapperRef.current.clientWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fires after slides have been reordered from a navigation event. Enables animation.
  useEffect(() => {
    if (slideState.reordered) {
      let currentSlide;

      if (slideState.navDirection === 'prev') {
        currentSlide = 0;
      }

      if (slideState.navDirection === 'next') {
        currentSlide = slidesVisible;
      }

      setSlideState({ ...slideState, reordered: false, animate: true, currentSlide });
    }
  }, [slideState.reordered]);

  // Handles animation
  useEffect(() => {
    if (slideState.animate && !slideState.animating) {
      setSlideState({ ...slideState, animating: true });

      const { slides } = slideState;
      let trimmedSlides;

      if (slideState.navDirection === 'prev') {
        trimmedSlides = slides.slice(0, slides.length - slidesVisible);
      }

      if (slideState.navDirection === 'next') {
        trimmedSlides = slides.slice(slidesVisible);
      }

      setTimeout(() => {
        setSlideState((prevState) => ({
          ...prevState,
          slides: trimmedSlides,
          currentSlide: 0,
          animate: false,
          animating: false
        }));
      }, 500);
    }
  }, [slideState.animate]);

  useEffect(() => {
    setSlidesVisible(breakpoints[getBreakpoint(width, breakpoints)].slidesToShow);
  }, [width]);

  const handlePrevious = useCallback(() => {
    if (slideState.animate || slideState.animating || slideState.reordered) return;
    const nomadSlides = slideState.slides
      .slice(slideState.slides.length - slidesVisible)
      .map((item) => ({ ...item, id: uuid() }));

    setSlideState({
      ...slideState,
      slides: [...nomadSlides, ...slideState.slides],
      reordered: true,
      currentSlide: slideState.currentSlide + slidesVisible,
      navDirection: 'prev'
    });
  });

  const handleNext = useCallback(() => {
    if (slideState.animate || slideState.animating || slideState.reordered) return;
    const nomadSlides = slideState.slides.slice(0, slidesVisible).map((item) => ({ ...item, id: uuid() }));
    setSlideState({
      ...slideState,
      slides: [...slideState.slides, ...nomadSlides],
      reordered: true,
      navDirection: 'next'
    });
  });

  const renderItems = () => {
    return slideState.slides.map((item, i) => {
      const slideWidth = carouselWidth / slidesVisible;
      const baseOffset = slideWidth * slideState.currentSlide;
      const slideOffset = (carouselWidth / slidesVisible) * i;

      return (
        <CarouselItem
          key={item.id || item.title}
          slideWidth={slideWidth}
          slideOffset={-baseOffset + slideOffset}
          animate={slideState.animate}
        >
          {renderItem(item.item)}
        </CarouselItem>
      );
    });
  };

  return (
    <Wrapper>
      <ButtonWrapper>
        <IconButton
          icon={ArrowDropleft}
          size="56px"
          onClick={handlePrevious}
          bgColor="none"
          bgColorHover="none"
          color={theme.colors.white}
          colorHover={theme.colors.primary}
        />
      </ButtonWrapper>
      <InnerWrapper ref={wrapperRef}>{renderItems()}</InnerWrapper>
      <ButtonWrapper>
        <IconButton
          icon={ArrowDropright}
          size="56px"
          onClick={handleNext}
          bgColor="none"
          bgColorHover="none"
          color={theme.colors.white}
          colorHover={theme.colors.primary}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  breakpoints: PropTypes.object
};

Carousel.defaultProps = {
  breakpoints: {
    small: { width: 640, slidesToShow: 1 },
    medium: { width: 832, slidesToShow: 2 },
    large: { width: 1024, slidesToShow: 2 }
  }
};

export default Carousel;
