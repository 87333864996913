import {
  FETCH_PLAYLISTS_REQUEST,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILURE,
  CREATE_PLAYLIST_REQUEST,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILURE,
  UPDATE_PLAYLIST_REQUEST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAILURE,
  DELETE_PLAYLIST_REQUEST,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAILURE
} from './constants';

const initialState = { data: [], loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAYLISTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_PLAYLISTS_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_PLAYLISTS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CREATE_PLAYLIST_REQUEST:
      return { ...state, loading: true };
    case CREATE_PLAYLIST_SUCCESS:
      return { ...state, loading: false };
    case CREATE_PLAYLIST_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case UPDATE_PLAYLIST_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PLAYLIST_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_PLAYLIST_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case DELETE_PLAYLIST_REQUEST:
      return { ...state, loading: true };
    case DELETE_PLAYLIST_SUCCESS:
      return { ...state, loading: false };
    case DELETE_PLAYLIST_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
