import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {queueNotification} from '../../store/notifications/actions';

import bdAuth from '../../../@packages/@bd-uikit-web/js-helpers/bdAuth';

const Wrapper = styled.span`
`;

const CustomFileUploadLabel = styled.label`
    border: 1px solid #ccc;
    display: inline-block;
    padding: 0.5em 1em;
    cursor: pointer;
`;

const CustomFileUploadInput = styled('input')`
    display: none;
`;

const CustomUploader = ({onUpload, allowedTypes, maxFileSizeMB, title}) => {
    const dispatch = useDispatch();
    const inputFileElement = useRef(null);

    const validateFile = (file) => {
        const fileSize = file.size / 1000000;
        const validType = allowedTypes.includes(file.type);
        const validSize = fileSize <= maxFileSizeMB;

        if (! validType) {
            dispatch(queueNotification({
                    type: 'ERROR', message: `Whoops that filetype is not allowed. The allowed types are ${
                    allowedTypes.join(', ')
                }`
            }));
            return false;
        }

        if (! validSize) {
            dispatch(queueNotification({
                    type: 'ERROR', message: `Whoops that file is too big. The maximum file size is ${maxFileSizeMB}MB. Your file is ${
                    parseInt(fileSize, 10)
                }MB`
            }));
            return false;
        }
        return true;
    };

    const handleUpload = (e) => {
        e.preventDefault();

        const files = e.target.files || e.dataTransfer.files;

        const fileValid = validateFile(files[0]);

        if (fileValid) {
            const formData = new FormData();
            formData.append('file', files[0]);

            const header = {
                'Authorization': bdAuth.getTokenHeader(),
                'cache-control': 'no-cache'
            }

            fetch(`https://upload.blackdove.io/files`, {
                method: 'POST',
                headers: header,
                body: formData,
            })
            .then(res => res.json())
            .then(image => {
                onUpload(image);
            })
            .catch(err => {
                onUpload(undefined);
            })
        }
    }

    const handleTriggerFileUpload = () => {
      inputFileElement.current.click()
    }

    return (
        <Wrapper onClick={handleTriggerFileUpload}>
            <CustomFileUploadLabel> Upload image </CustomFileUploadLabel> 
            <CustomFileUploadInput id="file-upload" type="file" onChange={handleUpload} accept="images/*" ref={inputFileElement}/>
        </Wrapper>
    )
}

CustomUploader.propTypes = {
    allowedTypes: PropTypes.arrayOf(PropTypes.string),
    maxFileSizeMB: PropTypes.number,
    onUpload: PropTypes.func
};

CustomUploader.defaultProps = {
    allowedTypes: ['*'],
    maxFileSizeMB: 1000,
    onUpload: null
};

export default CustomUploader;