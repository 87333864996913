import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import { lighten, darken } from 'polished';

import bdAuth from '../../../../@packages/@bd-uikit-web/js-helpers/bdAuth';

import GlobalStyles from './GlobalStyles';

import Navigation from './components/Navigation';
import Header from './components/Header';
import Modal from './components/Modal';
import NotificationCenter from '../../NotificationCenter';

import { fetchUser } from '../../../store/user/actions';
import { FETCH_USER_SUCCESS } from '../../../store/user/constants';
import { fetchSubscription } from '../../../store/subscription/actions';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Content = styled.div`
  margin-left: 100px;
  padding: 3em;
`;

const theme = {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  colors: {
    white: '#fff',
    black: '#000',
    whiteFaded: 'rgba(255,255,255,0.8)',
    base: '#171717',
    baseLighter: '#222',
    baseDarker: '#121212',
    primary: '#6173CF',
    primaryDarker: '#4a5ec8',
    primaryLighter: '#4a5ec8',
    secondary: '#333333',
    secondaryLighter: lighten(0.1, '#333333'),
    secondaryDarker: darken(0.1, '#333333'),
    light: '#d5d3d3',
    lightLighter: lighten(0.1, '#d5d3d3'),
    lightDarker: darken(0.2, '#d5d3d3'),
    error: '#eb2426',
    errorDarker: darken(0.15, '#eb2426'),
    success: '#2AD177',
    info: '#6173CF',
    alert: '#ffc107'
  }
};

const MainLayout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // Fetch data that is used throughout the app
  useEffect(() => {
    const token = bdAuth.getToken();

    if (!token?.access_token) {
      history.replace('/login');
    }

    const fetchData = async () => {
      if (!user?.data?.user) {
        const result = await dispatch(fetchUser());
        if (result.type === FETCH_USER_SUCCESS) {
          await dispatch(fetchSubscription(result?.payload?.user?.id));
        }
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <GlobalStyles />
        <Navigation />
        <Header />
        <Content>{children}</Content>
        <NotificationCenter />
        <Modal />
      </Wrapper>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

export default MainLayout;
