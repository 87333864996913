import {
  UPLOAD_ARTWORK_START,
  UPLOAD_ARTWORK_COMPLETE,
  UPLOAD_ARTWORK_FAILURE,
  UPLOAD_ARTWORK_ADD_FILE,
  UPLOAD_ARTWORK_PROGRESS,
  UPLOAD_ARTWORK_CANCEL,
  UPLOAD_ARTWORK_ABORTED
} from './constants';

const initialState = {
  file: null,
  data: {},
  progress: {},
  canceled: false,
  aborted: false,
  uploading: false,
  errors: [],
  xhr: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_ARTWORK_ADD_FILE:
      return {
        ...state,
        file: action.payload,
        aborted: false,
        canceled: false,
        uploading: false,
        data: {},
        progress: {},
        errors: []
      };
    case UPLOAD_ARTWORK_START:
      return { ...state, uploading: true, xhr: action.payload };
    case UPLOAD_ARTWORK_COMPLETE:
      return { ...state, uploading: false, data: action.payload, errors: [], xhr: null };
    case UPLOAD_ARTWORK_FAILURE:
      return { ...state, uploading: false, errors: action.errors };
    case UPLOAD_ARTWORK_PROGRESS:
      return { ...state, progress: action.payload };
    case UPLOAD_ARTWORK_CANCEL:
      return { ...state, canceled: true };
    case UPLOAD_ARTWORK_ABORTED:
      return { ...state, aborted: true, file: null };
    default:
      return state;
  }
};

export default reducer;
