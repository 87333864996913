import React, { useState, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import bdAuth from '../../../@packages/@bd-uikit-web/js-helpers/bdAuth';

import Button from '../../components/Button';
import { Form, TextInput, SubmitButton } from '../../components/Form';
import {
  Wrapper,
  Logo,
  Heading,
  SignupContainer,
  InputWrapper,
  ButtonWrapper,
  OrDivider,
  FbIcon,
  LoginOption
} from './styles';

import { createUser } from '../../store/user/actions';
import { CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from '../../store/user/constants';
import { authenticate } from '../../store/app/actions';
import { AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE } from '../../store/app/constants';
import { queueNotification } from '../../store/notifications/actions';

import colors from '../../util/colors';
import logo from '../../assets/images/blackdove-logo.png';
import fbIcon from '../../assets/images/icons/facebook.png';

const useQuery = () => {
  const location = useLocation().search;
  return new URLSearchParams(location);
};

const SignupPage = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ email: '', password: '', givenName: '' });
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));
  const handleSignupSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    if (formState.email.length === 0 || formState.password.length === 0 || formState.givenName.length === 0) {
      dispatch(queueNotification({ type: 'ERROR', message: 'You must enter all required data.' }));
      setSubmitting(false);
      return;
    }

    const userResult = await dispatch(createUser(formState));

    if (userResult.type === CREATE_USER_SUCCESS) {
      const result = await dispatch(authenticate({ username: formState.email, password: formState.password }));

      if (result.type === AUTHENTICATE_SUCCESS) {
        bdAuth._storeToken(result.payload);

        if (query.get('flow') === 'subscribe') {
          history.push('/account/subscriptions');
        } else {
          history.push('/');
        }
      }

      if (result.type === AUTHENTICATE_FAILURE) {
        const errorMessage = typeof result.errors === 'string' ? result.errors : 'Failed to login!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        setSubmitting(false);
      }
    }

    if (userResult.type === CREATE_USER_FAILURE) {
      const errorMessage = typeof userResult.errors === 'string' ? userResult.errors : 'Signup failed!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      <Logo src={logo} alt="Blackdove" />
      <Heading>The most efficient way to enjoy digital art.</Heading>
      <SignupContainer>
        <Form onSubmit={handleSignupSubmit}>
          <InputWrapper>
            <TextInput name="email" type="email" placeholder="Email" onChange={handleInputChange} />
            <TextInput name="password" type="password" placeholder="Create a Password" onChange={handleInputChange} />
            <TextInput
              name="givenName"
              type="text"
              placeholder="What should we call you?"
              onChange={handleInputChange}
            />
          </InputWrapper>
          <ButtonWrapper>
            <SubmitButton submitting={submitting} block round to="/account/subscriptions">
              Continue
            </SubmitButton>
            <OrDivider>Or</OrDivider>
            <Button
              to="/"
              bgColor={colors.facebookBlue}
              bgHoverColor={colors.facebookBlueLighter}
              disabled={submitting}
              block
              round
              outline
            >
              <FbIcon src={fbIcon} alt="" />
              Sign Up With Facebook
            </Button>
          </ButtonWrapper>
        </Form>
      </SignupContainer>
      <LoginOption>
        Already have an account? <Link to="/login?flow=subscribe">Login</Link>
      </LoginOption>
    </Wrapper>
  );
};

export default SignupPage;
