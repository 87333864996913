import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import { FETCH_PLAYLIST_REQUEST, FETCH_PLAYLIST_SUCCESS, FETCH_PLAYLIST_FAILURE } from './constants';

const fetchPlaylistRequest = () => ({ type: FETCH_PLAYLIST_REQUEST });
const fetchPlaylistSuccess = (payload) => ({ type: FETCH_PLAYLIST_SUCCESS, payload });
const fetchPlaylistFailure = (errors) => ({ type: FETCH_PLAYLIST_FAILURE, errors });

export const fetchPlaylist = (userId, playlistId) => async (dispatch) => {
  dispatch(fetchPlaylistRequest());
  try {
    const response = await bdReq.get(`/users/${userId}/playlists/${playlistId}`);
    if (response.success) {
      return dispatch(fetchPlaylistSuccess(response.body));
    }
    return dispatch(fetchPlaylistFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchPlaylistFailure(e));
  }
};
