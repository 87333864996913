import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import { FETCH_DISCOVER_REQUEST, FETCH_DISCOVER_SUCCESS, FETCH_DISCOVER_FAILURE } from './constants';

const fetchDiscoverRequest = () => ({ type: FETCH_DISCOVER_REQUEST });
const fetchDiscoverSuccess = (payload) => ({ type: FETCH_DISCOVER_SUCCESS, payload });
const fetchDiscoverFailure = (errors) => ({ type: FETCH_DISCOVER_FAILURE, errors });

export const fetchDiscover = (userId) => async (dispatch) => {
  dispatch(fetchDiscoverRequest());
  try {
    const response = await bdReq.get(`/users/${userId}/discover`);
    if (response.success) {
      return dispatch(fetchDiscoverSuccess(response.body));
    }
    return dispatch(fetchDiscoverFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchDiscoverFailure(e));
  }
};
