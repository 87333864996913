import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';

import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import Loader from '../../components/Loader';
import { PageTitle } from '../../components/Typography';
import { Label, Form, TextInput, SubmitButton } from '../../components/Form';

import { queueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div``;

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  useEffect(() => {
    if (user?.data?.user?.id) {
      setLoading(false);
    }
  }, [user]);

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleFormSubmit = useCallback(async (e) => {
    try {
      e.preventDefault();
      if (submitting) return;
      setSubmitting(true);

      if (!formState.oldPassword || !formState.newPassword || !formState.confirmNewPassword) {
        dispatch(queueNotification({ type: 'ERROR', message: 'You must fill out all fields.' }));
        setSubmitting(false);
        return;
      }

      if (formState.newPassword !== formState.confirmNewPassword) {
        dispatch(queueNotification({ type: 'ERROR', message: 'Passwords do not match.' }));
        setSubmitting(false);
        return;
      }

      const params = {
        oldPassword: formState.oldPassword,
        password: formState.newPassword,
        passwordAgain: formState.confirmNewPassword
      };

      const { success } = await bdReq.put(`/users/${user?.data?.user?.id}/password`, params);

      if (!success) {
        dispatch(queueNotification({ type: 'ERROR', message: 'Could not update password!' }));
        setSubmitting(false);
        return;
      }

      dispatch(queueNotification({ type: 'SUCCESS', message: 'Your password has been changed.' }));
      history.push('/account');
    } catch (err) {
      const errorMessage = typeof err.message === 'string' ? err.message : 'Could not update password!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>Edit Profile</PageTitle>
          <Form onSubmit={handleFormSubmit}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1 / 3]}>
                <Box width={[1]}>
                  <Label>Old Password</Label>
                  <TextInput
                    type="password"
                    name="oldPassword"
                    placeholder="Old Password"
                    onChange={handleInputChange}
                  />
                </Box>
                <Box width={[1]}>
                  <Label>New Password</Label>
                  <TextInput
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                    onChange={handleInputChange}
                  />
                </Box>
                <Box width={[1]}>
                  <Label>Confirm New Password</Label>
                  <TextInput
                    type="password"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
            </Flex>
            <SubmitButton submitting={submitting}>Save Changes</SubmitButton>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

export default ChangePasswordPage;
