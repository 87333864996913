import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Dropdown = styled.div`
  position: absolute;
  background: black;
  width: 200px;
  top: 35px;
  right: 0;
  height: auto;
  overflow: hidden;
  z-index: 9999;
`;

const MenuBaseContainer = styled.ul`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  list-style: none;
  padding: 0;
`;

const MenuItem = styled.li`
  display: inline-flex;
  align-items: center;    
  text-decoration: none;
  color: #fff;
  padding: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
`;

const ItemIcon = styled.span`
  width: 24px;
  margin-right: 10px;
`;

const ItemTitle = styled.span`
  font-size: 18px;
`;

const CustomDropdown = ({
  visible = false,
  listItems = [],
  dropdownRef
}) => {

  const memoizedItems = useMemo(() =>
    listItems.map(item => (
      <MenuItem key={item.id} onClick={item.action}>
        <ItemIcon>{item.icon}</ItemIcon>
        <ItemTitle>{item.title}</ItemTitle>
      </MenuItem>
    )),
    [listItems]
  );

  if (!visible) return null;

  return (
    <Dropdown ref={dropdownRef}>
      <MenuBaseContainer>
        {memoizedItems}
      </MenuBaseContainer>
    </Dropdown>
  )
}

CustomDropdown.propTypes = {
  listItems: PropTypes.array,
  visible: PropTypes.bool
}

export default CustomDropdown;