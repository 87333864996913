import Dispatcher from './Dispatcher';

if (!window.Blackdove) {
  window.Blackdove = {};
}

if (!window.Blackdove.rootDispatcher) {
  window.Blackdove.rootDispatcher = new Dispatcher();
}

export default window.Blackdove.rootDispatcher;
