import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  width: 240px;
  display: inline-block;
  margin: 0 1.5em 2em 0;
  color: white;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const ThumbWrapper = styled.div`
  width: 240px;
  height: 240px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.8em;
`;

const Thumb = styled.div`
  width: 50%;
  height: 50%;
  flex: 1/2;
  transform: rotate(270deg);
  background-image: url(${({ bgImage }) => bgImage});
  border: solid 3px ${({ theme }) => theme.colors.base};
  background-position: center;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.baseDarker};
  overflow: hidden;
`;

const PlaylistTitle = styled.span`
  font-size: 1em;
`;

const PlaylistPreview = ({ playlist, editable, inList, userId, galleryUserId, pageName, imageUrl }) => {
  const thumbs = playlist.artworks
    .map((art) => {
      return <Thumb key={`${playlist.id}-${art.id}`} bgImage={art.media ? art?.media?.image?.low?.portrait : ''} />;
    })
    .slice(0, playlist.artworks.length < 4 ? playlist.artworks.length : 4);

  if (thumbs.length < 4) {
    const missingThumbs = 4 - thumbs.length;
    for (let i = 0; i < missingThumbs; i++) {
      thumbs.push(<Thumb key={`${playlist.id}-${i}`} bgImage="" />);
    }
  }

  return (
    <StyledLink
      to={`/playlist/${playlist.id}${editable ? '?editable=true&backPath=/my-playlists' : '?backPath=/discover'}`}
    >
      <ThumbWrapper>{thumbs}</ThumbWrapper>
      <PlaylistTitle>{playlist.name}</PlaylistTitle>
    </StyledLink>
  );
};

PlaylistPreview.propTypes = {
  playlist: PropTypes.object.isRequired,
  inList: PropTypes.bool,
  editable: PropTypes.bool
};

PlaylistPreview.defaultProps = {
  inList: false,
  editable: false
};

export default PlaylistPreview;
