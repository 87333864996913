export const FETCH_FOLLOWERS_REQUEST = 'FETCH_FOLLOWERS_REQUEST';
export const FETCH_FOLLOWERS_SUCCESS = 'FETCH_FOLLOWERS_SUCCESS';
export const FETCH_FOLLOWERS_FAILURE = 'FETCH_FOLLOWERS_FAILURE';

export const CREATE_FOLLOWER_REQUEST = 'CREATE_FOLLOWER_REQUEST';
export const CREATE_FOLLOWER_SUCCESS = 'CREATE_FOLLOWER_SUCCESS';
export const CREATE_FOLLOWER_FAILURE = 'CREATE_FOLLOWER_FAILURE';

export const UPDATE_FOLLOWER_REQUEST = 'UPDATE_FOLLOWER_REQUEST';
export const UPDATE_FOLLOWER_SUCCESS = 'UPDATE_FOLLOWER_SUCCESS';
export const UPDATE_FOLLOWER_FAILURE = 'UPDATE_FOLLOWER_FAILURE';

export const DELETE_FOLLOWER_REQUEST = 'DELETE_FOLLOWER_REQUEST';
export const DELETE_FOLLOWER_SUCCESS = 'DELETE_FOLLOWER_SUCCESS';
export const DELETE_FOLLOWER_FAILURE = 'DELETE_FOLLOWER_FAILURE';
