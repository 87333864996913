import store from 'store';
import Cookies from 'js-cookie';

import bdReq from './bdReq';
import bdErrorTracking from './bdErrorTracking';
import rootDispatcher from './rootDispatcher';

const STORE_KEYS = {
  user: 'auth__user',
  token: 'auth__token',
  muteStatus: 'mute_Status',
  artworkInterval: 'artwork_Interval'
};

const topDomain = (function topDomain() {
  const match = location.hostname.match(/(\.\w+.\.\w+$|localhost)/);
  if (!match) {
    return null;
  }
  return match[0];
}());

const bdAuth = {
  _userEndpoint: '/me',
  _appId: null,

  authenticate(username, password, facebookToken = null) {
    let authReqDetails = null;
    let postData = null;

    if (facebookToken === null) {
      postData = {
        grant_type: 'password',
        username,
        password,
      };
    } else {
      postData = {
        grant_type: 'facebook',
        accessToken: facebookToken,
      };
    }

    return bdReq.post('/oauth/token', postData)
      .then((details) => {
        this._storeToken(details.body);
        authReqDetails = details;
        return this.storeUser();
      })
      .then(() => authReqDetails)
      .catch((err) => {
        this.clear();
        throw new Error(err);
      });
  },

  setAppId(appId) {
    this._appId = appId;
  },

  getAppId() {
    return this._appId;
  },

  getLoginURL(registerPath = null) {
    const domain = (topDomain === 'localhost' ? topDomain : `account${topDomain}`);
    const currentAppUrl = location.href.replace(/\/$/, '');
    let url = `//${domain}?redirect-url=${currentAppUrl}`;
    if (registerPath) {
      const registerUrl = `${currentAppUrl}${registerPath}`;
      url += `&register-url=${registerUrl}`;
    }
    return url;
  },

  getTokenQuery() {
    const {token_type: tokenType, access_token: accessToken, expirationTime, runAsUserId} = this.getToken();
    let queryString = `?token-type=${tokenType}&token=${accessToken}&expiration-time=${expirationTime}`;
    if (runAsUserId) {
      queryString += '&run-as=${runAsUserId}';
    }
    return queryString;
  },

  getTokenLogin() {
    const runAsUserId = bdReq.getURLParameter('run-as') || null;
    const token = {
      tokenType: bdReq.getURLParameter('token-type'),
      accessToken: bdReq.getURLParameter('token'),
      expirationTime: bdReq.getURLParameter('expiration-time'),
    };

    function checkParamsValid() {
      return Boolean(token.tokenType && token.accessToken && token.expirationTime);
    }

    return {
      paramsValid: checkParamsValid(),
      authenticate: () => {
        this.clear();
        token.expirationTime = Number(token.expirationTime);
        return this._authenticateWithToken(token, runAsUserId);
      },
    };
  },

  isAuthenticated() {
    return this.getTokenHeader() !== null;
  },

  getToken() {
    return Cookies.getJSON(STORE_KEYS.token);
  },

  getMuteStatus() {
    return Cookies.getJSON(STORE_KEYS.muteStatus);
  },

  getArtworkInterval() {
    return Cookies.getJSON(STORE_KEYS.artworkInterval);
  },



  getTokenHeader() {
    const token = this.getToken();
    const tokenSet = token && token.token_type ? `${token.token_type} ${token.access_token}` : null;
    return tokenSet;
  },

  checkToken() {
    const token = this.getToken();
    const timestamp = this._getCurrentTime();
    const tokenExpired = timestamp > token.expirationTime;

    if (!tokenExpired) {
      return Promise.resolve();
    }

    return this._refreshAuth(token.refresh_token);
  },

  checkResponseValid({status}) {
    switch (status) {
      case 400:
        // token expired
        const token = this.getToken();
        console.log('token', token);
        const timestamp = this._getCurrentTime();
        const tokenExpired = timestamp > token.expirationTime;
    
        if (tokenExpired) {
          this.clear();
          location.reload();
        }
        break;
      case 401:
        rootDispatcher.dispatch('AUTH:UNAUTHORIZED', {status});
        return false;
      case 403:
        rootDispatcher.dispatch('AUTH:FORBIDDEN', {status});
        return false;
    }

    return true;
  },

  clear() {
    store.remove('api-url'); // TODO remove this, only temp so no-one has this in their store anymore
    store.remove(STORE_KEYS.user);
    Cookies.remove(STORE_KEYS.token, {domain: topDomain});
  },

  getUser(includeAll = false) {
    const user = store.get(STORE_KEYS.user);
    if (typeof user !== 'object') {
      return null;
    }
    return includeAll ? user : user.user;
  },

  setUserEndpoint(endpoint) {
    this._userEndpoint = endpoint;
  },

  getUserEndpoint() {
    return this._userEndpoint;
  },

  storeUser() {
    return new Promise((resolve, reject) => {
      if (!this.isAuthenticated()) {
        // user not authenticated yet
        resolve({success: false, body: {message: 'Not Authenticated'}});
        return;
      }

      bdReq.get(this._userEndpoint)
        .then(({success, body}) => {
          if (!success) {
            resolve({
              success,
              body,
            });
          }

          bdErrorTracking.setUserContext(body.user);
          store.set(STORE_KEYS.user, body);

          resolve({
            success,
            body,
          });
        })
        .catch(reject);
    });
  },

  updateUser(user) {
    store.set(STORE_KEYS.user, user);
  },

  _storeToken(token) {
    if (!token || !token.token_type) {
      return;
    }
    const newToken = token;
    newToken.expirationTime = this._getTokenExpirationTime(newToken.expires_in);
    Cookies.set(STORE_KEYS.token, newToken, {domain: topDomain, expires: 7});
  },

  _storeMuteStatus(status) {
    if (status === undefined) {
      status = false;
      Cookies.set(STORE_KEYS.muteStatus, newstatus);
      return;
    }
    const newstatus = status;
    Cookies.set(STORE_KEYS.muteStatus, newstatus);
  },

  _storeArtworkInterval(interval) {
    if (interval === undefined) {
      Cookies.set(STORE_KEYS.artworkInterval, interval);
      return;
    }
    if (interval === 0) {
      interval = null;
      Cookies.set(STORE_KEYS.artworkInterval, interval);
      return;
    }
    const newInterval = interval;
    Cookies.set(STORE_KEYS.artworkInterval, newInterval);
  },

  _authenticateWithToken({tokenType, accessToken, expirationTime}, runAsUserId = null) {
    const currentTime = this._getCurrentTime();

    if (expirationTime < currentTime) {
      return Promise.reject(`token expired (current time ${currentTime} is later than expiration-time ${expirationTime})`);
    }

    const token = {
      access_token: accessToken,
      token_type: tokenType,
      expirationTime,
      runAsUserId,
    };

    Cookies.set(STORE_KEYS.token, token, {domain: topDomain, expires: 7});

    return this.storeUser()
      .then(() => token)
      .catch((err) => {
        this.clear();
        throw new Error(err);
      });
  },

  _getCurrentTime() {
    // in seconds
    return Math.floor(Date.now() / 1000);
  },

  _getTokenExpirationTime(expiresIn) {
    return this._getCurrentTime() + expiresIn;
  },

  _refreshAuth(refreshToken) {
    return bdReq.post('/oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }, true)
    .then(({body}) => this._storeToken(body))
    .catch((err) => {
      this.clear();
      throw new Error(err);
    });
  },

};

// for debug purposes
window.clearAuth = () => {
  bdAuth.clear();
};

export default bdAuth;

