import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import { FETCH_PRICE_TIERS_REQUEST, FETCH_PRICE_TIERS_SUCCESS, FETCH_PRICE_TIERS_FAILURE } from './constants';

const fetchPriceTiersRequest = () => ({ type: FETCH_PRICE_TIERS_REQUEST });
const fetchPriceTiersSuccess = (payload) => ({ type: FETCH_PRICE_TIERS_SUCCESS, payload });
const fetchPriceTiersFailure = (errors) => ({ type: FETCH_PRICE_TIERS_FAILURE, errors });

export const fetchPriceTiers = () => async (dispatch) => {
  dispatch(fetchPriceTiersRequest());
  try {
    const response = await bdReq.get('/pricing');
    if (response.success) {
      return dispatch(fetchPriceTiersSuccess(response.body));
    }
    return dispatch(fetchPriceTiersFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchPriceTiersFailure(e));
  }
};
