import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loader from '../../components/Loader';
import { PageTitle } from '../../components/Typography';
import { Table, TableHead, TableBody, TableRow, HeaderCell, BodyCell, TableLink } from '../../components/Table';
import { Wrapper, AddButton } from './styles';

import { fetchDevices } from '../../store/devices/actions';

const Devices = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const devices = useSelector((state) => state.devices);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        await dispatch(fetchDevices(user?.data?.user?.id));
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.data?.user?.id]);

  const renderDevices = () =>
    devices.data.map((device) => {
      return (
        <TableRow key={device.id}>
          <BodyCell>
            <TableLink to={`/devices/${device.id}`}>{device.uid}</TableLink>
          </BodyCell>
          <BodyCell>
            <TableLink to={`/devices/${device.id}`}>{device.name}</TableLink>
          </BodyCell>
        </TableRow>
      );
    });

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>Devices</PageTitle>
          <AddButton to="/devices/new">Add New Device</AddButton>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCell>Device ID</HeaderCell>
                <HeaderCell>Device Name</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderDevices()}</TableBody>
          </Table>
        </>
      )}
    </Wrapper>
  );
};

export default Devices;
