import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-top: 4em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const Logo = styled.img`
  display: block;
  width: 30%;
  margin: 0 auto 2em auto;
`;

export const Heading = styled.h1`
  margin-bottom: 1.5em;
  font-weight: 300;
`;

export const SignupContainer = styled.div`
  width: 45%;
`;

export const InputWrapper = styled.div`
  padding-bottom: 2.5em;
  width: 75%;
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
`;

export const OrDivider = styled.span`
  display: block;
  font-size: 1.3em;
  margin-bottom: 1.1em;
  font-weight: 300;
  text-transform: uppercase;
`;

export const FbIcon = styled.img`
  display: inline-block;
  vertical-align: top;
  margin-top: -1px;
  margin-right: 0.6em;
`;

export const LoginOption = styled.span``;
