import bdAuth from '../../../@packages/@bd-uikit-web/js-helpers/bdAuth';

import {
  UPLOAD_ARTWORK_START,
  UPLOAD_ARTWORK_COMPLETE,
  UPLOAD_ARTWORK_FAILURE,
  UPLOAD_ARTWORK_ADD_FILE,
  UPLOAD_ARTWORK_PROGRESS,
  UPLOAD_ARTWORK_CANCEL,
  UPLOAD_ARTWORK_ABORTED
} from './constants';

export const uploadArtworkAddFile = (payload) => ({ type: UPLOAD_ARTWORK_ADD_FILE, payload });
export const uploadArtworkCancel = () => ({ type: UPLOAD_ARTWORK_CANCEL });

const uploadArtworkStart = (payload) => ({ type: UPLOAD_ARTWORK_START, payload });
const uploadArtworkComplete = (payload) => ({ type: UPLOAD_ARTWORK_COMPLETE, payload });
const uploadArtworkFailure = (errors) => ({ type: UPLOAD_ARTWORK_FAILURE, errors });
const uploadArtworkProgress = (payload) => ({ type: UPLOAD_ARTWORK_PROGRESS, payload });
const uploadArtworkAborted = () => ({ type: UPLOAD_ARTWORK_ABORTED });

export const uploadArtwork = (file) => async (dispatch, getState) => {
  try {
    const xhr = new XMLHttpRequest();

    dispatch(uploadArtworkStart(xhr));
    const url = 'https://upload.blackdove.io/files?convert=true';

    // Setup form data to send
    const formData = new FormData();
    formData.append('file', file);
    formData.append('convert', true);

    xhr.upload.addEventListener(
      'progress',
      (e) => {
        if (!e.lengthComputable) return;
        const state = getState();
        if (state.newArtwork.canceled) {
          state.newArtwork.xhr.abort();
          dispatch(uploadArtworkAborted());
          return;
        }
        dispatch(uploadArtworkProgress({ current: e.loaded, total: e.total }));
      },
      false
    );

    xhr.addEventListener(
      'readystatechange',
      (e) => {
        const { target } = e;
        if (target.readyState !== e.target.DONE) return;

        if (target.status === 200) {
          const response = JSON.parse(target.responseText);
          dispatch(uploadArtworkComplete(response));
        } else {
          const state = getState();
          if (!state.newArtwork.canceled) {
            dispatch(uploadArtworkFailure([`Looks like something went wrong. Please try again. (${e.target.status})`]));
          }
        }
      },
      false
    );

    xhr.addEventListener(
      'error',
      (e) => {
        dispatch(uploadArtworkFailure([`Looks like something went wrong. Please try again. (${e.target.status})`]));
      },
      false
    );

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', bdAuth.getTokenHeader());
    xhr.setRequestHeader('cache-control', 'no-cache');
    xhr.send(formData);
    return;
  } catch (e) {
    dispatch(uploadArtworkFailure(e));
  }
};
