import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { LoaderAlt } from 'emotion-icons/boxicons-regular';

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpinner = styled.div`
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${spin} 1s infinite;
`;

const Loader = ({ size }) => {
  return (
    <Wrapper>
      <StyledSpinner>
        <LoaderAlt width={`${size}px`} height={`${size}px`} />
      </StyledSpinner>
    </Wrapper>
  );
};

Loader.propTypes = {
  size: PropTypes.number
};

Loader.defaultProps = {
  size: 45
};

export default Loader;
