import { FETCH_ARTIST_REQUEST, FETCH_ARTIST_SUCCESS, FETCH_ARTIST_FAILURE } from './constants';

const initialState = { data: {}, loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTIST_REQUEST:
      return { ...state, loading: true };
    case FETCH_ARTIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_ARTIST_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
