import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import {
  FETCH_PLAYLISTS_REQUEST,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILURE,
  CREATE_PLAYLIST_REQUEST,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILURE,
  UPDATE_PLAYLIST_REQUEST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAILURE,
  DELETE_PLAYLIST_REQUEST,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAILURE
} from './constants';

const fetchPlaylistsRequest = () => ({ type: FETCH_PLAYLISTS_REQUEST });
const fetchPlaylistsSuccess = (payload) => ({ type: FETCH_PLAYLISTS_SUCCESS, payload });
const fetchPlaylistsFailure = (errors) => ({ type: FETCH_PLAYLISTS_FAILURE, errors });

export const fetchPlaylists = (userId) => async (dispatch) => {
  dispatch(fetchPlaylistsRequest());
  try {
    const response = await bdReq.get(`/users/${userId}/playlists`);
    if (response.success) {
      return dispatch(fetchPlaylistsSuccess(response.body));
    }
    return dispatch(fetchPlaylistsFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchPlaylistsFailure(e));
  }
};

const createPlaylistRequest = () => ({ type: CREATE_PLAYLIST_REQUEST });
const createPlaylistSuccess = (payload) => ({ type: CREATE_PLAYLIST_SUCCESS, payload });
const createPlaylistFailure = (errors) => ({ type: CREATE_PLAYLIST_FAILURE, errors });

export const createPlaylist = (userId, params) => async (dispatch) => {
  dispatch(createPlaylistRequest());
  try {
    const response = await bdReq.post(`/users/${userId}/playlists`, params);
    if (response.success) {
      return dispatch(createPlaylistSuccess(response.body));
    }
    return dispatch(createPlaylistFailure(response.body.message));
  } catch (e) {
    return dispatch(createPlaylistFailure(e));
  }
};

const updatePlaylistRequest = () => ({ type: UPDATE_PLAYLIST_REQUEST });
const updatePlaylistSuccess = (payload) => ({ type: UPDATE_PLAYLIST_SUCCESS, payload });
const updatePlaylistFailure = (errors) => ({ type: UPDATE_PLAYLIST_FAILURE, errors });

export const updatePlaylist = (userId, playlistId, params) => async (dispatch) => {
  dispatch(updatePlaylistRequest());
  try {
    const response = await bdReq.put(`/users/${userId}/playlists/${playlistId}`, params);
    if (response.success) {
      return dispatch(updatePlaylistSuccess(response.body));
    }
    return dispatch(updatePlaylistFailure(response.body.message));
  } catch (e) {
    return dispatch(updatePlaylistFailure(e));
  }
};

const deletePlaylistRequest = () => ({ type: DELETE_PLAYLIST_REQUEST });
const deletePlaylistSuccess = (payload) => ({ type: DELETE_PLAYLIST_SUCCESS, payload });
const deletePlaylistFailure = (errors) => ({ type: DELETE_PLAYLIST_FAILURE, errors });

export const deletePlaylist = (userId, playlistId) => async (dispatch) => {
  dispatch(deletePlaylistRequest());
  try {
    const response = await bdReq.delete(`/users/${userId}/playlists/${playlistId}`);
    if (response.success) {
      return dispatch(deletePlaylistSuccess(response.body));
    }
    return dispatch(deletePlaylistFailure(response.body.message));
  } catch (e) {
    return dispatch(deletePlaylistFailure(e));
  }
};
