import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import {
  AUTHENTICATE_REQUEST,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  LOGOUT,
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_MODAL
} from './constants';

const authenticateRequest = () => ({ type: AUTHENTICATE_REQUEST });
const authenticateSuccess = (payload) => ({ type: AUTHENTICATE_SUCCESS, payload });
const authenticateFailure = (errors) => ({ type: AUTHENTICATE_FAILURE, errors });

export const authenticate = (params) => async (dispatch) => {
  dispatch(authenticateRequest());
  try {
    const response = await bdReq.post('/oauth/token', { ...params, grant_type: 'password' });
    if (response.success) {
      return dispatch(authenticateSuccess(response.body));
    }
    return dispatch(authenticateFailure(response.body.message));
  } catch (e) {
    return dispatch(authenticateFailure(e));
  }
};

export const logout = () => ({ type: LOGOUT });

export const openModal = (payload) => ({ type: OPEN_MODAL, payload });
export const closeModal = () => ({ type: CLOSE_MODAL });
export const toggleModal = () => ({ type: TOGGLE_MODAL });
