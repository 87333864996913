import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div``;

const EditArtwork = () => {
  return (
    <Wrapper>
      <span>New Artwork</span>
    </Wrapper>
  );
};

export default EditArtwork;
