import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from 'react-router-dom';

import IconButton from '../IconButton';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && !['list'].includes(prop)
})`
  color: white;
  text-decoration: none;
  display: block;
  width: ${({ orientation }) => (orientation === 'horizontal' ? '280px' : '160px')};
  margin-right: ${({ list }) => (list ? '1.4em' : 'none')};
  padding-bottom: ${({ list }) => (list ? '1.4em' : 'none')};
  position: relative;
`;

export const ArtworkLink = styled(Link)`
  display: block;
  width: ${({ orientation }) => (orientation === 'horizontal' ? '280px' : '160px')};
  height: ${({ orientation }) => (orientation === 'horizontal' ? '160px' : '280px')};
  margin-bottom: 1em;
`;

export const Preview = styled.div`
  width: ${({ orientation }) => (orientation === 'horizontal' ? '280px' : '160px')};
  height: ${({ orientation }) => (orientation === 'horizontal' ? '160px' : '280px')};
  position: relative;
  
  ::before {
    content: '';
    position: absolute;
    width: ${({ orientation }) => (orientation === 'vertical' ? '160px' : '280px')};
    height: ${({ orientation }) => (orientation === 'vertical' ? '280px' : '160px')};
    top: '0px';
    left: '0px';
    background: url(${({ bgImage }) => bgImage}) center center no-repeat black;
    background-size: cover;
    transform: 'rotate(0deg)';
  }
`;

export const VideoPlayer = styled.video`
  width: 280px;
  height: 160px;
  position: absolute;
  top: ${({ orientation }) => (orientation === 'horizontal' ? '0px' : '60px')};
  left: ${({ orientation }) => (orientation === 'horizontal' ? '0px' : '-60px')};
  transform: ${({ orientation }) => (orientation === 'horizontal' ? 'rotate(0deg)' : 'rotate(90deg)')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  background: black;
  z-index: 50;
`;

export const ArtTitle = styled.span`
  display: block;
  font-size: 0.9em;
  margin-bottom: 0.2em;
`;

export const ArtistName = styled.span`
  display: block;
  font-size: 0.7em;
`;

export const OverlayWrapper = styled.div`
  width: ${({ orientation }) => (orientation === 'horizontal' ? '280px' : '160px')};
  height: ${({ orientation }) => (orientation === 'horizontal' ? '160px' : '280px')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const AddButton = styled(IconButton)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: all;
  background: ${({ theme }) => theme.colors.base};

  &:hover {
    background: ${({ theme }) => theme.colors.baseLighter};
  }
`;

export const PlayButton = styled(IconButton)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  pointer-events: all;
  background: ${({ theme }) => theme.colors.base};
  border-radius: 50px;

  &:hover {
    background: ${({ theme }) => theme.colors.baseLighter};
  }
`;
