import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';
import { DateTime } from 'luxon';

import Loader from '../../components/Loader';
import { PageTitle } from '../../components/Typography';
import Panel from '../../components/Panel';
import Button from '../../components/Button';

import { fetchUser } from '../../store/user/actions';

const Wrapper = styled.div``;

const PanelTitle = styled.h3`
  font-size: 2em;
  margin: 0;
  text-transform: none;
  margin-bottom: ${({ marginBottom }) => marginBottom || 1}em;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  font-size: 1.2em;
`;

const Value = styled.span`
  display: block;
`;

const AccountPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const subscription = useSelector((state) => state.subscription);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user?.data?.user?.id && !subscription.loading) {
      setLoading(false);
    }
  }, [user, subscription]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchUser());
    };
    fetchData();
  }, []);

  const userData = user?.data?.user;
  const subscriptionPlan = subscription?.data?.plan || { name: 'Free' };
  const dob =
    typeof userData?.dateOfBirth === 'string'
      ? DateTime.fromString(userData?.dateOfBirth, 'yyyy-mm-dd')
      : DateTime.fromJSDate(userData?.dateOfBirth);

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>Account Overview</PageTitle>
          <Flex flexWrap="wrap">
            <Box width={[1, 1, 3 / 5]}>
              <Panel p={35} mr={[0, 0, 25]} mb={25}>
                <PanelTitle marginBottom="0.5">Profile</PanelTitle>
                <Flex flexWrap="wrap" mb={25}>
                  <Box width={[1, 1, 1 / 2]} mb={20}>
                    <Label>Email</Label>
                    <Value>{userData?.email}</Value>
                  </Box>
                  <Box width={[1, 1, 1 / 2]} mb={20}>
                    <Label>Date of Birth</Label>
                    <Value>{dob.toLocaleString(DateTime.DATE_FULL)}</Value>
                  </Box>
                  <Box width={[1, 1, 1 / 2]} mb={20}>
                    <Label>Country</Label>
                    <Value>{userData?.country}</Value>
                  </Box>
                  <Box width={[1, 1, 1 / 2]} mb={20}>
                    <Label>State</Label>
                    <Value>{userData?.state}</Value>
                  </Box>
                </Flex>
                <Button small to="/account/edit">
                  Edit Profile
                </Button>
                <Button small to="/account/password">
                  Change Password
                </Button>
                <Button small to="/account/notifications">
                  Manage Notifications
                </Button>
              </Panel>
            </Box>
            <Box width={[1, 1, 2 / 5]}>
              <Panel p={35} mr={0} mb={25}>
                <PanelTitle marginBottom="0">Subscription Status</PanelTitle>
                <p>Current Subscription: {subscriptionPlan?.name}</p>
                <Button small to="/account/subscriptions">
                  Manage Subscription
                </Button>
              </Panel>
            </Box>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

export default AccountPage;
