import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Check, InfoCircle, Error, X } from 'emotion-icons/boxicons-regular';

import { openNotification, closeNotification, dequeueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div`
  position: fixed;
  max-width: 100vw;
  left: 75px;
  bottom: 50px;
  z-index: 1000;
`;

const Notification = styled.div`
  display: flex;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: ${({ visible }) =>
    visible
      ? 'transform 250ms ease-in-out, opacity 250ms ease-in-out '
      : 'transform 100ms ease-in-out 500ms, opacity 100ms ease-in-out 500ms'};
  transform: ${({ visible }) => (visible ? 'translateY(0px)' : 'translateY(100px)')};
`;

const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  background: ${({ theme, color }) => theme.colors[color]};
  border-radius: 50px;
  width: 50px;
  height: 50px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  background: ${({ theme, color }) => theme.colors[color]};
  border-radius: 50px;
  height: 50px;
  padding: 0 30px;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: ${({ visible }) =>
    visible
      ? 'transform 500ms ease-in-out 250ms, opacity 500ms ease-in-out 250ms'
      : 'transform 500ms ease-in-out, opacity 500ms ease-in-out'};
  transform: ${({ visible }) => (visible ? 'translateX(25px)' : 'translateX(10px)')};
`;

const NotificationCenter = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  // Listens for a change to the notification queue and then shows the first queued notification
  useEffect(() => {
    if (notifications.data.length > 0) {
      dispatch(openNotification());
    }
  }, [notifications.data]);

  // Closes the current notification after an interval
  useEffect(() => {
    if (notifications.visible) {
      setTimeout(() => {
        dispatch(closeNotification());
        setTimeout(() => {
          dispatch(dequeueNotification());
        }, 1000);
      }, 5000);
    }
  }, [notifications.visible]);

  const currentNotification = notifications.data[0];

  const renderIcon = () => {
    switch (currentNotification?.type) {
      case 'SUCCESS':
        return <Check size={30} />;
      case 'ALERT':
        return <Error size={30} />;
      case 'ERROR':
        return <X size={30} />;
      case 'INFO':
        return <InfoCircle size={30} />;
      default:
        return <InfoCircle size={30} />;
    }
  };

  return (
    <Wrapper>
      <Notification visible={notifications.visible}>
        <IconWrapper visible={notifications.visible} color={currentNotification?.type?.toLowerCase()}>
          {renderIcon()}
        </IconWrapper>
        <MessageWrapper visible={notifications.visible} color={currentNotification?.type?.toLowerCase()}>
          {currentNotification?.message}
        </MessageWrapper>
      </Notification>
    </Wrapper>
  );
};

export default NotificationCenter;
