import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import {
  UPDATE_COLLECTION_THUMBNAIL_REQUEST,
  UPDATE_COLLECTION_THUMBNAIL_SUCCESS,
  UPDATE_COLLECTION_THUMBNAIL_FAILURE
} from './constants';

const updateCollectionThumbnailRequest = () => ({ type: UPDATE_COLLECTION_THUMBNAIL_REQUEST });
const updateCollectionThumbnailSuccess = (payload) => ({ type: UPDATE_COLLECTION_THUMBNAIL_SUCCESS, payload });
const updateCollectionThumbnailFailure = (errors) => ({ type: UPDATE_COLLECTION_THUMBNAIL_FAILURE, errors });

export const updateCollectionThumbnail = (params) => async (dispatch) => {
  dispatch(updateCollectionThumbnailRequest());
  try {
    const response = await bdReq.post(`/replacplaylistthumbnail`, params);
    if (response.success) {
      return dispatch(updateCollectionThumbnailSuccess(response.body));
    }
    return dispatch(updateCollectionThumbnailFailure(response.body.message));
  } catch (e) {
    return dispatch(updateCollectionThumbnailFailure(e));
  }
};