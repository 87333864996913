import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import reducer from './reducer';

import RouteWithLayout from './components/RouteWithLayout';

import PublicLayout from './components/Layouts/Public';
import MainLayout from './components/Layouts/Main';

import Discover from './pages/Discover';
import MyPlaylists from './pages/MyPlaylists';
import Playlist from './pages/Playlist';
import ArtworkDetail from './pages/ArtworkDetail';
import ArtistDetail from './pages/ArtistDetail';
import Devices from './pages/Devices';
import NewDevice from './pages/NewDevice';
import EditDevice from './pages/EditDevice';
import ManageArtwork from './pages/ManageArtwork';
import NewArtwork from './pages/NewArtwork';
import EditArtwork from './pages/EditArtwork';
import PageNotFound from './pages/PageNotFound';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';
import AccountPage from './pages/Account';
import EditAccountPage from './pages/EditAccount';
import ChangePasswordPage from './pages/ChangePassword';
import NotificationSettingsPage from './pages/NotificationSettings';
import SubscriptionsPage from './pages/Subscriptions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, {}, composeEnhancers(applyMiddleware(thunk)));

// TODO: Set this based on NODE_ENV
// const stripePromise = loadStripe('pk_test_CwFNQLF0fkyOp98BpMWd97hD');
const stripePromise = loadStripe('pk_live_8YPTwOAeydlQbXQKYSHVDAI6');

const App = () => (
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Switch>
          {/* Implementation Finished */}
          <RouteWithLayout exact path="/" component={Discover} layout={MainLayout} />
          <RouteWithLayout path="/discover" component={Discover} layout={MainLayout} />
          <RouteWithLayout path="/my-playlists" component={MyPlaylists} layout={MainLayout} />
          <RouteWithLayout path="/playlist/:id" component={Playlist} layout={MainLayout} />
          <RouteWithLayout path="/artwork/:id" component={ArtworkDetail} layout={MainLayout} />
          <RouteWithLayout path="/artist/:id" component={ArtistDetail} layout={MainLayout} />
          <RouteWithLayout exact path="/devices" component={Devices} layout={MainLayout} />
          <RouteWithLayout exact path="/devices/new" component={NewDevice} layout={MainLayout} />
          <RouteWithLayout path="/devices/:id" component={EditDevice} layout={MainLayout} />

          {/* Pending */}
          <RouteWithLayout exact path="/manage-artwork" component={ManageArtwork} layout={MainLayout} />
          <RouteWithLayout exact path="/manage-artwork/new" component={NewArtwork} layout={MainLayout} />
          <RouteWithLayout path="/manage-artwork/:artworkId" component={EditArtwork} layout={MainLayout} />

          {/* Pending */}
          <RouteWithLayout exact path="/account" component={AccountPage} layout={MainLayout} />
          <RouteWithLayout exact path="/account/edit" component={EditAccountPage} layout={MainLayout} />
          <RouteWithLayout exact path="/account/password" component={ChangePasswordPage} layout={MainLayout} />
          <RouteWithLayout
            exact
            path="/account/notifications"
            component={NotificationSettingsPage}
            layout={MainLayout}
          />
          <RouteWithLayout exact path="/account/subscriptions" component={SubscriptionsPage} layout={MainLayout} />
          <RouteWithLayout exact path="/login" component={LoginPage} layout={PublicLayout} />
          <RouteWithLayout exact path="/signup" component={SignupPage} layout={PublicLayout} />

          {/* Implementation Finished */}
          <RouteWithLayout path="*" component={PageNotFound} layout={MainLayout} />
        </Switch>
      </BrowserRouter>
    </Elements>
  </Provider>
);

export default App;
