import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Checkmark } from 'emotion-icons/ion-ios';
import { Flex, Box } from 'reflexbox';

import bdReq from '@bd-uikit-web/js-helpers/bdReq';

import Loader from '../../Loader';
import Button from '../../Button';

import { fetchDevices } from '../../../store/devices/actions';
import { queueNotification } from '../../../store/notifications/actions';

const Wrapper = styled.div``;

const DeviceList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.baseLighter};
  margin-top: 2em;
  margin-bottom: 1.5em;
`;

const DeviceItem = styled.li`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseLighter};
  overflow: hidden;
`;

const DeviceLink = styled.a`
  color: white;
  padding: 5px 10px;
  display: flex;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const DeviceDetails = styled.div`
  padding: 10px 20px 10px 10px;
`;

const DeviceName = styled.span`
  display: block;
`;

const DeviceUid = styled.span`
  display: block;
`;

const StyledCheckmark = styled(Checkmark)`
  margin: 10px 0 0 0;
  margin-left: ${({ visible }) => (visible ? '0px' : '-44px')};
  transition: margin-left 200ms ease-in-out;
  color: ${({ theme }) => theme.colors.primary};
`;

const PlayArtworkModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const devices = useSelector((state) => state.devices);
  const params = useSelector((state) => state.app.modalParams);
  const [loading, setLoading] = useState(true);
  const [selectedDevices, setSelectedDevices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        await dispatch(fetchDevices(user?.data?.user?.id));
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeviceClick = useCallback((deviceId) => {
    if (selectedDevices.includes(deviceId)) {
      setSelectedDevices((prev) => prev.filter((d) => d !== deviceId));
    } else {
      setSelectedDevices((prev) => [...prev, deviceId]);
    }
  });

  const handlePlayClick = useCallback(async () => {
    try {
      const endpoint = `/users/${user.data?.id}/devices/play`;
      const { success } = await bdReq.post(endpoint, {
        playlistId: params.playlistId,
        artworkId: params.artworkId,
        deviceIds: selectedDevices
      });

      if (success) {
        dispatch(queueNotification({ type: 'SUCCESS', message: 'Playing artwork on selected devices.' }));
        closeModal();
        return;
      }

      dispatch(queueNotification({ type: 'ERROR', message: 'Could not play artwork on selected devices.' }));
      closeModal();
      return;
    } catch (e) {
      dispatch(queueNotification({ type: 'ERROR', message: e.message }));
      closeModal();
    }
  });

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <h3>Play on Device</h3>
          <span>Select the device(s) to play this artwork to.</span>
          <DeviceList>
            {devices.data?.map((device) => (
              <DeviceItem key={device.uid}>
                <DeviceLink onClick={() => handleDeviceClick(device.id)}>
                  <StyledCheckmark size={35} visible={selectedDevices.includes(device.id)} />
                  <DeviceDetails>
                    <DeviceName>{device.name}</DeviceName>
                    <DeviceUid>{device.uid}</DeviceUid>
                  </DeviceDetails>
                </DeviceLink>
              </DeviceItem>
            ))}
          </DeviceList>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 2, 1 / 2]} pr={2}>
              <Button block ghost to="/devices">
                Manage Devices
              </Button>
            </Box>
            <Box width={[1, 1 / 2, 1 / 2]} pl={2}>
              <Button block disabled={selectedDevices.length === 0} onClick={handlePlayClick}>
                Play
              </Button>
            </Box>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

PlayArtworkModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default PlayArtworkModal;
