import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';

const baseStyles = ({ theme }) =>
  css`
    background: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary};
    border-radius: 5px;
    color: ${theme.colors.white};
    height: 3em;
    padding: 1em;
    margin-bottom: 1.2em;
    width: 100%;
    font-size: 1em;
    font-weight: 300;

    ::placeholder {
      color: ${theme.colors.lightDarker};
      text-transform: none;
    }

    &:focus {
      border: 1px solid ${theme.colors.secondaryLighter};
      outline: none;
    }
  `;

const inlineStyles = () =>
  css`
    display: inline-block;
  `;

const disabledStyles = ({ theme }) =>
  css`
    color: ${theme.colors.lightDarker};
    cursor: not-allowed;
  `;

const StyledInput = styled('input', {
  shouldForwardProp: (prop) => isPropValid(prop) && !['inline'].includes(prop)
})`
  ${baseStyles}
  ${({ inline }) => inline && inlineStyles}
  ${({ disabled }) => disabled && disabledStyles}
`;

const TextInput = ({ type, defaultValue, ...props }) => {
  return <StyledInput type={type} defaultValue={defaultValue} {...props} />;
};

TextInput.propTypes = {
  type: PropTypes.string,
  defaultValue: PropTypes.string
};

TextInput.defaultProps = {
  type: 'text',
  defaultValue: ''
};

export default TextInput;
