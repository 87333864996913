import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';

import bdReq from '../../../@packages/@bd-uikit-web/js-helpers/bdReq';

import Loader from '../../components/Loader';
import { PageTitle } from '../../components/Typography';
import { Label, Form, SubmitButton, Switch } from '../../components/Form';

import { queueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div``;

const NotificationSettingsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    introductionEmails: false
  });

  useEffect(() => {
    if (user?.data?.user?.id) {
      setFormState({ introductionEmails: user?.data?.user?.emailNotification });
      setLoading(false);
    }
  }, [user]);

  const handleInputChange = useCallback((e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  });

  const handleFormSubmit = useCallback(async (e) => {
    try {
      e.preventDefault();
      if (submitting) return;
      setSubmitting(true);

      const params = {
        emailNotification: formState.introductionEmails
      };

      const { success } = await bdReq.put(`/users/${user?.data?.user?.id}/email-notification`, params);

      if (!success) {
        dispatch(queueNotification({ type: 'ERROR', message: 'Could not save settings!' }));
        setSubmitting(false);
        return;
      }

      dispatch(queueNotification({ type: 'SUCCESS', message: 'Your settings have been saved.' }));
      history.push('/account');
    } catch (err) {
      const errorMessage = typeof err.message === 'string' ? err.message : 'Could not save settings!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <PageTitle>Notifications Settings</PageTitle>
          <Form onSubmit={handleFormSubmit}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1 / 3]}>
                <Label>Introduction Emails</Label>
                <Switch
                  name="introductionEmails"
                  defaultValue={user?.data?.user?.emailNotification}
                  onChange={handleInputChange}
                />
              </Box>
            </Flex>
            <SubmitButton submitting={submitting}>Save Changes</SubmitButton>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

export default NotificationSettingsPage;
