import React from 'react';
import { render } from 'react-dom';

import bdReq from '@bd-uikit-web/js-helpers/bdReq';
import bdAuth from '@bd-uikit-web/js-helpers/bdAuth';
import bdErrorTracking from '@bd-uikit-web/js-helpers/bdErrorTracking';

import App from './App';

bdReq.setApiUrl('https://api.blackdove.io');
// bdReq.setApiUrl('http://localhost:3004');
bdAuth.setAppId('fdea8d89jufd89kufd');
bdAuth.setUserEndpoint('/me');
bdErrorTracking.install('bd-web-app');

render(<App />, document.getElementById('app'));
