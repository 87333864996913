import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';
import { DateTime } from 'luxon';

import Loader from '../../components/Loader';
import Carousel from '../../components/Carousel';
import ArtworkPreview from '../../components/ArtworkPreview';

import { fetchArtist } from '../../store/selectedArtist/actions';
import { FETCH_ARTIST_FAILURE } from '../../store/selectedArtist/constants';
import { queueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div``;

const ArtistImage = styled.img`
  display: block;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  margin-bottom: 2em;
`;

const ArtistName = styled.h2`
  margin-bottom: 0.5em;
`;

const ArtistTagline = styled.span``;

const MetadataTitle = styled.span`
  display: block;
  font-size: 0.75em;
  color: ${({ theme }) => theme.colors.lightDarker};
  margin-top: 1em;
  margin-bottom: 0.4em;
`;

const MetadataValue = styled.span`
  display: block;
  margin-bottom: 1em;
  font-size: 0.9em;
`;

const Tag = styled.span`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 1em;
  font-size: 0.75em;
  margin-right: 1em;
  margin-bottom: 0.5em;
`;

const ArtistHeadline = styled.h1``;

const ArtworkHeadline = styled.h2``;

const ArtistDescription = styled.p`
  line-height: 1.8em;
  margin-bottom: 3em;
`;

const ArtistDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const artist = useSelector((state) => state.selectedArtist);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const artistResult = await dispatch(fetchArtist(id));

      if (artistResult.type === FETCH_ARTIST_FAILURE) {
        const errorMessage = typeof artistResult.errors === 'string' ? artistResult.errors : 'Failed to fetch artist!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        return;
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const friendlyDate = DateTime.fromISO(artist?.data?.dateOfBirth).toLocaleString(DateTime.DATE_MED);
  const fullName = `${artist?.data?.givenName} ${artist?.data?.surname}`;

  const artistPlaylist = artist?.data?.playlists?.find((pl) => pl.id === artist?.data?.playlistId);

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 3, 1 / 3]} pr={20}>
              <ArtistImage src={artist?.data?.media?.images?.avatar?.high} alt={fullName} />
              <ArtistName>{fullName}</ArtistName>
              <ArtistTagline>Some Tagline</ArtistTagline>

              <MetadataTitle>Born</MetadataTitle>
              <MetadataValue>{friendlyDate}</MetadataValue>

              <MetadataTitle>Website</MetadataTitle>
              <Tag>
                <a
                  href={
                    artist?.data?.website?.includes('http') ? artist?.data?.website : `http://${artist?.data?.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {artist?.data?.website}
                </a>
              </Tag>
            </Box>
            <Box width={[1, 2 / 3, 2 / 3]}>
              <ArtistHeadline>{fullName}</ArtistHeadline>
              <ArtistDescription
                dangerouslySetInnerHTML={{ __html: artist?.data?.bio?.replace(/(?:\r\n|\r|\n)/g, '<br />') }}
              />
              <ArtworkHeadline>{`${artist?.data?.givenName}'s Artwork`}</ArtworkHeadline>
              <Carousel
                items={artistPlaylist?.artworks}
                renderItem={(item) => (
                  <ArtworkPreview artwork={item} artist={artist?.data} editable={false} previewOrientation="vertical" />
                )}
              />
            </Box>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

export default ArtistDetail;
