import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import Loader from '../../components/Loader';
import PlaylistPreview from '../../components/PlaylistPreview';

import { fetchDiscover } from '../../store/discover/actions';
import { fetchStaff } from '../../store/staff/actions';

const Wrapper = styled.div``;

const Discover = () => {
  const dispatch = useDispatch();
  const discover = useSelector((state) => state.discover);
  const staff = useSelector((state) => state.staff);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.data?.user?.id) {
        await dispatch(fetchStaff());
        await dispatch(fetchDiscover(user?.data?.user?.id));
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.data?.user?.id]);

  const checkShowAll = () => {
    const { groups } = user.data;
    return groups.includes('Curator') || groups.includes('Support') || user.data.user.id === 'blackdove';
  };

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          {discover.data.map((playlist) => (
            <PlaylistPreview key={playlist.id} playlist={playlist} inList />
          ))}
          {checkShowAll() && (
            <PlaylistPreview
              key="all"
              playlist={{
                id: 'all',
                name: 'All Artworks (Staff Only)',
                artworks: staff.data
              }}
              inList
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Discover;
