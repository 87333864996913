import {
  FETCH_DEVICES_REQUEST,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,
  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  CREATE_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE
} from './constants';

const initialState = { data: [], loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICES_REQUEST:
      return { ...state, loading: true };
    case FETCH_DEVICES_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_DEVICES_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CREATE_DEVICE_REQUEST:
      return { ...state, loading: true };
    case CREATE_DEVICE_SUCCESS:
      return { ...state, loading: false, data: [...state.data, action.payload], errors: [] };
    case CREATE_DEVICE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case UPDATE_DEVICE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_DEVICE_SUCCESS:
      return { ...state, loading: false, errors: [] };
    case UPDATE_DEVICE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case DELETE_DEVICE_REQUEST:
      return { ...state, loading: true };
    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((device) => device.id !== action.payload.id),
        errors: []
      };
    case DELETE_DEVICE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
