import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { Pencil, Trash } from 'emotion-icons/boxicons-regular';

// TODO: bdReq and bdAuth should be extracted into a proper package
import bdReq from '@bd-uikit-web/js-helpers/bdReq';

import IconButton from '../IconButton';
import ArtworkPreview from '../ArtworkPreview';

import { fetchPlaylists, updatePlaylist, deletePlaylist } from '../../store/playlists/actions';
import {
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAILURE,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAILURE
} from '../../store/playlists/constants';
import { fetchPlaylist } from '../../store/selectedPlaylist/actions';
import { queueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div``;

const ArtworkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const PlaylistManagment = styled.span`
  display: inline-block;
  margin-left: 10px;
  transform: translateY(-4px);
`;

const Playlist = ({ playlist, userId, backPath, searchQuery, priceTiers, editable }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleRemovePlaylist = useCallback(async () => {
    if (!editable || !confirm('Are you sure you want to delete this playlist?')) return;
    const result = await dispatch(deletePlaylist(userId, playlist?.data?.id));

    if (result.type === DELETE_PLAYLIST_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Playlist deleted!' }));
      history.push(backPath);
    }

    if (result.type === DELETE_PLAYLIST_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Playlist could not be deleted!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  const handleEditPlaylist = useCallback(async () => {
    if (!editable) return;

    // TODO: Make this a form in a modal
    const playlistName = prompt('Playlist name', playlist?.data?.name);
    if (playlistName === null) return;

    if (playlistName === '') {
      dispatch(queueNotification({ type: 'ERROR', message: 'Playlist name is required.' }));
      return;
    }

    const result = await dispatch(updatePlaylist(userId, playlist?.data?.id, { playlistName }));

    if (result.type === UPDATE_PLAYLIST_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Playlist name changed!' }));
      dispatch(fetchPlaylists(userId));
      dispatch(fetchPlaylist(userId, playlist?.data?.id));
    }

    if (result.type === UPDATE_PLAYLIST_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Failed to update playlist!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  const handleRemoveArtwork = useCallback(async (artworkId) => {
    try {
      if (!editable) return;
      const { success } = await bdReq.delete(`/users/${userId}/playlists/${playlist?.data?.id}/artworks/${artworkId}`);

      if (success) {
        dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully removed arwork from playlist' }));
        dispatch(fetchPlaylist(userId, playlist?.data?.id));
      }
    } catch (e) {
      dispatch(queueNotification({ type: 'ERROR', message: e.message }));
    }
  });

  const filteredArtworks = playlist?.data?.artworks.filter((artwork) => {
    if (searchQuery) {
      const normalizedQuery = searchQuery.toLowerCase();
      return (
        artwork.name.toLowerCase().indexOf(normalizedQuery) >= 0 ||
        artwork._embedded.artist.givenName.toLowerCase().indexOf(normalizedQuery) >= 0 ||
        artwork._embedded.artist.surname.toLowerCase().indexOf(normalizedQuery) >= 0
      );
    }
    return true;
  });

  const renderArtwork = () => {
    return filteredArtworks.map((artwork) => {
      if (artwork.openMarketplaceStandardLicense) {
        const tier = priceTiers.find((item) => item.id === artwork.openMarketplaceStandardLicense);
        artwork.artworkPrice = tier ? tier.price : '';
      }

      return (
        <ArtworkPreview
          key={artwork.id}
          artwork={artwork}
          artist={artwork?._embedded?.artist}
          playlist={playlist?.data}
          onRemoveClick={editable ? () => handleRemoveArtwork(artwork.id) : null}
          editable={editable}
          previewOrientation={artwork.orientation == 'portrait' ? 'vertical' : 'horizontal'}
          playable
          list
        />
      );
    });
  };

  return (
    <Wrapper>
      <h3>
        {playlist?.data?.name}
        {editable && (
          <PlaylistManagment>
            <IconButton
              icon={Pencil}
              onClick={handleEditPlaylist}
              bgColor="none"
              bgColorHover="none"
              color={theme.colors.lightDarker}
              colorHover={theme.colors.primary}
            />
            <IconButton
              icon={Trash}
              onClick={handleRemovePlaylist}
              bgColor="none"
              bgColorHover="none"
              color={theme.colors.lightDarker}
              colorHover={theme.colors.error}
            />
          </PlaylistManagment>
        )}
      </h3>
      <ArtworkWrapper>{renderArtwork()}</ArtworkWrapper>
    </Wrapper>
  );
};

Playlist.propTypes = {
  playlist: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
  priceTiers: PropTypes.array.isRequired,
  editable: PropTypes.bool
};

Playlist.defaultProps = {
  searchQuery: null,
  editable: false
};

export default Playlist;
