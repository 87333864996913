import styled from '@emotion/styled';

import Button from '../../components/Button';

export const Wrapper = styled.div`
  position: relative;
`;

export const AddButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 1.5em;
`;
