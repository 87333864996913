import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';
import { DateTime } from 'luxon';

import Loader from '../../components/Loader';
import Carousel from '../../components/Carousel';
import ArtworkPreview from '../../components/ArtworkPreview';

import { fetchArtwork } from '../../store/selectedArtwork/actions';
import { FETCH_ARTWORK_FAILURE } from '../../store/selectedArtwork/constants';
import { fetchArtist } from '../../store/selectedArtist/actions';
import { FETCH_ARTIST_FAILURE } from '../../store/selectedArtist/constants';
import { queueNotification } from '../../store/notifications/actions';

const Wrapper = styled.div``;

const PreviewWrapper = styled.div`
  width: 280px;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.6);
`;

const ArtworkTitle = styled.h2`
  margin-bottom: 0.5em;
`;

const ArtistLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
`;

const MetadataTitle = styled.span`
  display: block;
  font-size: 0.75em;
  color: ${({ theme }) => theme.colors.lightDarker};
  margin-top: 1em;
  margin-bottom: 0.4em;
`;

const MetadataValue = styled.span`
  display: block;
  margin-bottom: 1em;
  font-size: 0.9em;
`;

const Tag = styled.span`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 1em;
  font-size: 0.75em;
  margin-right: 1em;
  margin-bottom: 0.5em;
`;

const ArtworkHeadline = styled.h1``;

const ArtworkDescription = styled.p`
  line-height: 1.8em;
  margin-bottom: 3em;
`;

const ArtistHeadline = styled.h1``;

const ArtistDescription = styled.p`
  line-height: 1.8em;
`;

const ArtworkDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const artwork = useSelector((state) => state.selectedArtwork);
  const artist = useSelector((state) => state.selectedArtist);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const artworkResult = await dispatch(fetchArtwork(id));

      if (artworkResult.type === FETCH_ARTWORK_FAILURE) {
        const errorMessage =
          typeof artworkResult.errors === 'string' ? artworkResult.errors : 'Failed to fetch artwork!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        return;
      }

      const artistResult = await dispatch(fetchArtist(artworkResult?.payload?.artistId));

      if (artistResult.type === FETCH_ARTIST_FAILURE) {
        const errorMessage = typeof artistResult.errors === 'string' ? artistResult.errors : 'Failed to fetch artist!';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        return;
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const friendlyDate = DateTime.fromISO(artwork?.data?.createdAt).toLocaleString(DateTime.DATE_MED);

  const artistPlaylist = artist?.data?.playlists?.find((pl) => pl.id === artist?.data?.playlistId);

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 3, 1 / 3]} pr={20}>
              <PreviewWrapper>
                <ArtworkPreview
                  key={artwork?.data?.id}
                  artwork={artwork?.data}
                  artist={artist?.data}
                  playlist={null}
                  editable={false}
                  previewOrientation="horizontal"
                  showMetadata={false}
                />
              </PreviewWrapper>
              <ArtworkTitle>{artwork?.data?.name}</ArtworkTitle>
              <ArtistLink
                to={`/artist/${artist?.data?.id}`}
              >{`${artist?.data?.givenName} ${artist?.data?.surname}`}</ArtistLink>

              <MetadataTitle>Date Added</MetadataTitle>
              <MetadataValue>{friendlyDate}</MetadataValue>

              <MetadataTitle>Artwork Orientation</MetadataTitle>
              <MetadataValue>{artwork?.data?.orientation}</MetadataValue>

              <MetadataTitle>Tags</MetadataTitle>
              {artwork?.data?.tags?.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </Box>
            <Box width={[1, 2 / 3, 2 / 3]}>
              <ArtworkHeadline>{artwork?.data?.name}</ArtworkHeadline>
              <ArtworkDescription>{artwork?.data?.description}</ArtworkDescription>
              <ArtistHeadline>{`${artist?.data?.givenName} ${artist?.data?.surname}`}</ArtistHeadline>
              <ArtistDescription
                dangerouslySetInnerHTML={{ __html: artist?.data?.bio?.replace(/(?:\r\n|\r|\n)/g, '<br />') }}
              />
              <Carousel
                items={artistPlaylist?.artworks}
                renderItem={(item) => (
                  <ArtworkPreview artwork={item} artist={artist?.data} editable={false} previewOrientation="vertical" />
                )}
              />
            </Box>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

export default ArtworkDetail;
