import fetch from 'isomorphic-fetch';
import bdErrorTracking from './bdErrorTracking';
import bdAuth from './bdAuth';
import rootDispatcher from './rootDispatcher';
import getEnvName from './getEnvName';

export default {

  _getEnvApiUrl() {
    const envName = getEnvName();
    if (envName === 'dev') {
      return 'https://api.dev.blackdove.io';
    } else if (envName === 'staging') {
      return 'https://api.staging.blackdove.io';
    }
    return 'https://api.blackdove.io';
  },

  _getApiUrl() {
    const url = this._apiUrl || this._getEnvApiUrl();
    return url;
  },

  setApiUrl(url) {
    this._apiUrl = url;
  },

  _base(method, endpoint, data, skipAuth = false) {
    const upperMethod = method.toUpperCase();
    const isAuthenticated = bdAuth.isAuthenticated();
    const tokenHeader = bdAuth.getTokenHeader();
    const token = bdAuth.getToken() || {};
    const appId = bdAuth.getAppId();
    let basePromise = Promise.resolve();
    const headers = {
      Accept: 'application/json',
    };

    let query = appId ? this._constructQueryString({client_id: appId}) : '';

    if (typeof data === 'object' && upperMethod === 'GET') {
      query += this._constructQueryString(data, Boolean(appId));
    } else if (typeof data === 'object') {
      headers['Content-Type'] = 'application/json';
    }

    if (isAuthenticated && !skipAuth) {
      headers.Authorization = tokenHeader;
      basePromise = bdAuth.checkToken();
    }

    if (typeof token.runAsUserId === 'string') {
      headers['X-Run-As'] = token.runAsUserId;
    }

    const url = `${this._getApiUrl()}${endpoint}${query}`;
    let response = null;

    return basePromise
      .then(() => this._fetch(url, {
        method: upperMethod,
        headers,
        body: method !== 'GET' ? JSON.stringify(data) : null,
      }))
      .then((res) => {
        if (res.status === 500) {
          rootDispatcher.dispatch({
            type: 'AUTH:INTERNAL_SERVER_ERROR',
            data: {status},
          });
          throw new Error('Request Failed (500)');
        }

        bdAuth.checkResponseValid(res);
        response = res;

        return res.text();
      })
      .then((textBody) => {
        let parsedBody;

        try {
          parsedBody = JSON.parse(textBody);
        } catch (err) {
          parsedBody = textBody;
        }

        if (!response.ok) {
          bdErrorTracking.captureException(parsedBody);
        }

        return {
          success: response.ok,
          status: response.status,
          body: parsedBody,
        };
      })
      .catch((err) => {
        rootDispatcher.dispatch({
          type: 'REQ:REQUEST_FAILED',
          data: err,
        });
        bdErrorTracking.captureException(err);
        throw new Error(`${upperMethod} ${url} Request Failed`);
      });
  },

  _fetch(url, options, timeout = 15000) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('request timeout'));
      }, timeout);

      fetch(url, options)
        .then(resolve, reject);
    });
  },

  _constructQueryString(data, toAppend = false) {
    let query = toAppend ? '' : '?';

    for (const name in data) {
      const value = data[name];
      query += `${encodeURIComponent(name)}=${encodeURIComponent(value)}&`;
    }

    return query;
  },

  getURLParameter(name) {
    return decodeURIComponent(
      (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(location.search) ||
      [null, ''])[1].replace(/\+/g, '%20')
    ) || null;
  },

  get(endpoint, query) {
    return this._base('GET', endpoint, query);
  },

  post(endpoint, body, skipAuth = false) {
    return this._base('POST', endpoint, body, skipAuth);
  },

  put(endpoint, body) {
    return this._base('PUT', endpoint, body);
  },

  patch(endpoint, body) {
    return this._base('PATCH', endpoint, body);
  },

  delete(endpoint, body) {
    return this._base('DELETE', endpoint, body);
  },

};
